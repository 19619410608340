import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
// import { AudioVisualizer } from 'react-audio-visualize';
// import _ from "lodash";
import numeral from "numeral";

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import './AudioConvertToMP3.css';




function AudioConvertToMP3AudioList(props) {
  const {
    audioFiles,
    setAudioFiles,
    currentTime,
    setCurrentTime,
    playSound,
    pauseSound,
    restartSound,
    pauseElementSounds,
    widthScaleFactor,
    setWidthScaleFactor,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [audioEls, setAudioEls] = useState();

  // let currentTime;

  const changeCurrentTime = (event, id) => { 
    setCurrentTime({
      id: id, 
      currentTime: event.target.currentTime 
    })
  }

  const deleteAudioFileHandler = (id) => {
    const deletedList = audioFiles.filter(element => {
      return element.id !== id;
    });

    setAudioFiles(deletedList);
  }

  const playSoundHandler = (event, id, audioFiles) => {
    const otherElIds = [];

    for (const audioFile of audioFiles) {
      if (audioFile.id !== id) {
        otherElIds.push(audioFile.id);
      }
    }

    pauseElementSounds(otherElIds);
    
    playSound(event, id);
  }

  const restartSoundHandler = (event, id, audioFiles) => {
    const otherElIds = [];

    for (const audioFile of audioFiles) {
      if (audioFile.id !== id) {
        otherElIds.push(audioFile.id);
      }
    }

    pauseElementSounds(otherElIds);
    
    restartSound(event, id);
  }


  useMemo(() => {
      // console.log('Computing expensive value...');
      if (audioFiles.length > 0) {
        setAudioEls(
            <ul>
              {audioFiles.map(element => {
                return (
                  <li key={element.id}>
                    <div>
                      <div>
                      {' '}{element.file.name}
                      {' '}({numeral(element.file.size).format('0.0 b')})
                      </div>
                      <div>
                        <audio className='audioConvertToMP3AudioEl'
                          id={element.id}
                          src={URL.createObjectURL(element.file)}
                          controls
                          onTimeUpdate={(event) => {
                            changeCurrentTime(event, element.id) 
                          }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
        );
      }
    }, [audioFiles]);


    
  // let displayWaves;

  // if (audioFiles.length > 0) {
  //   displayWaves = (
  //     <ul>
  //       {audioFiles.map((element, index) => {
  //         // console.log(element)
  //         const containerHeight = 100;
  //         const waveHeight = element.bufferMax * containerHeight;
  //         const waveWidth = element.audioBuffer.duration * 1 * widthScaleFactor;
          
  //         return (
  //           <li key={element.id}>
  //             <div className='audioConvertToMP3ListItem'>
  //               <div>
  //                 <span className='audioConvertToMP3ListTitle'>
  //                   {/* File {index + 1} */}
  //                 </span>
  //                 <span>
  //                  {' '}{element.file.name}
  //                  {' '}({numeral(element.file.size).format('0.0 b')})
  //                 </span>
  //               </div>
  //               <div 
  //                 style={{
  //                   height: containerHeight,
  //                   paddingTop: (containerHeight - waveHeight) / 2,
  //                   paddingBottom: (containerHeight - waveHeight) / 2,
  //                   border: "0.1px solid lightgray",
  //                 }}
  //               >
  //                 <AudioVisualizer
  //                   // ref={visualizerRef}
  //                   // ref={document.getElementById(audioFiles[0].id)}
  //                   blob={element.file}
  //                   // width={500}
  //                   width={waveWidth}
  //                   height={waveHeight}
  //                   barWidth={1}
  //                   gap={0}
  //                   barColor={'#f76565'}
  //                   barPlayedColor={'gray'}
  //                   currentTime={element.id === currentTime.id ? currentTime.currentTime : 0}
  //                 />
  //               </div>
  //               <div className='audioConvertToMP3ListControl'>
  //                 <button className='audioConvertToMP3ListControlButton'
  //                   title={'play'}
  //                   onClick={(event) => { 
  //                     playSoundHandler(event, element.id, audioFiles); 
  //                   }}
  //                 >
  //                   {/* (play-button)  */}
  //                   {marks.playMark}
  //                 </button>
  //                 <button className='audioConvertToMP3ListControlButton'
  //                   title={'pause'}
  //                   onClick={(event) => { pauseSound(event, element.id) }}
  //                 >
  //                   {/* (pause-button) */}
  //                   {marks.pauseMark}
  //                 </button>
  //                 <button className='audioConvertToMP3ListControlButton'
  //                   title={'restart'}
  //                   onClick={(event) => { 
  //                     restartSoundHandler(event, element.id, audioFiles);
  //                   }}
  //                 >
  //                   {marks.shareMark}
  //                   {/* (restart-button) */}
  //                 </button>
  //                 {element.id === currentTime.id && (
  //                   <div>
  //                     {numeral(currentTime.currentTime).format('00:00')}
  //                   </div>
  //                 )}
  //               </div>
  //               {element.id !== currentTime.id && (
  //                 <div>{' '}</div>
  //               )}
  //               {/* <div>
  //                 <button className='btnBase'
  //                   onClick={() => { deleteAudioFileHandler(element.id) }}
  //                 >
  //                   Delete
  //                 </button>
  //               </div> */}
  //             </div>
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   );
  // }

  return (
    <Fragment>
      <div>
        {audioEls}
      </div>

      {/* <div>
        {displayWaves}
      </div> */}
    </Fragment>
  )
}

export default AudioConvertToMP3AudioList;