import { Fragment, useEffect, useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import AudioEditor from "./components/AudioEditor/AudioEditor.jsx";
import Backdrop from "./components/Backdrop/Backdrop.jsx";
import Layout from './components/Layout/Layout.jsx';
import Loader from './components/Loader/Loader.jsx';
import TranscribeAudio from "./components/TranscribeAudio/TranscribeAudio.tsx";
// import VoiceGenerator from "./components/VoiceGenerator/VoiceGenerator.jsx";
import VoiceGenerator2 from "./components/VoiceGenerator2/VoiceGenerator2.jsx";
import VoiceGenerator2NoEn from "./components/VoiceGenerator2/VoiceGenerator2NoEn/VoiceGenerator2NoEn.jsx";
import AnswerFinder from "./components/AnswerFinder/AnswerFinder.jsx";
// import TransLator from './components/Translator/Translator';

import AuthCheck from './components/Auth/AuthCheck.jsx';
import GetAuth from './components/Auth/GetAuth.jsx';
import GetLoginUsers from './components/Auth/GetLoginUsers.jsx';
import SetUserNameData from './components/Auth/SetUserNameData';
// import GetDeferredPrompt from './components/UI/GetDeferredPrompt';
// import GetWindowData from './components/UI/GetWindowData';

import Bucket from "./components/BucketComponents/Bucket/Bucket.jsx";
import GetSubscriptionData from './components/subscription/GetSubscriptionData.jsx';
import HeadMetaTag from './components/UI/HeadMetaTag';

import ServiceBuckets from './components/BucketComponents/ServiceBuckets/ServiceBuckets.jsx';

import UserSocketConnect from './components/SocketConnect/UserSocketConnect.jsx';

import UserInfo from './pages/UserInfo/UserInfo.js';
import AboutPage from "./pages/AboutPage/AboutPage.jsx";
import TermsOfUse from './pages/TermsOfUse/TermsOfUse.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js'

import LoadParamFile from "./components/LoadParamFile/LoadParamFile.jsx";
import VideoToAudio from "./components/VideoToAudio/VideoToAudio.jsx";

// import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
// import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
// import UserInfo from './pages/UserInfo/UserInfo';

import { useStore } from './hook-store/store.js';

import { 
    getCustomerDataSubscriptions, 
    // getCurrentSubscription, 
    // fetchPrices 
} from './utils/stripe.js';



const lsUuid = localStorage.getItem('lsUuid') ? localStorage.getItem('lsUuid') : '';


//// for dev
// export const KURASTORAGE_BACKENDURL = 'http://localhost:4000';
// export const SUBSCRIPTION_BACKEND_URL = KURASTORAGE_BACKENDURL;
// export const BACKEND_URL = 'http://localhost:4000';
// export const subscriptionBasicPriceId = "price_1MJyehIwXirsfm9gD9c7ctve";
// export const subscriptionMiniPriceId = "price_1MJyQGIwXirsfm9gGTTnTuD9";
// export const loginPageLink =  `http://localhost:8501/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = `http://localhost:8501/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const kuraStorageLink = `http://localhost:8503`;
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';
// export const TEXTAUDIOTOOLSNODE_URL = 'http://localhost:4004';


//// for test deploy
// export const KURASTORAGE_BACKENDURL = 'https://dokmp19c.spaceeight.net'
// export const SUBSCRIPTION_BACKEND_URL = KURASTORAGE_BACKENDURL;
// export const BACKEND_URL = KURASTORAGE_BACKENDURL;
// export const subscriptionBasicPriceId = "price_1MRZjTIwXirsfm9gbYrBwdO5";
// export const subscriptionMiniPriceId = "price_1MRZffIwXirsfm9gPjWau3HS";
// export const loginPageLink = `https://authsso.spaceeight.net/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = `https://authsso.spaceeight.net/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const kuraStorageLink = `https://do-bucket-frontend-app-test.web.app`;
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';
// export const TEXTAUDIOTOOLSNODE_URL = 'https://dokmp19-b2c.spaceeight.net';


//// for deploy
export const KURASTORAGE_BACKENDURL = 'https://dokmp19.spaceeight.net'
export const SUBSCRIPTION_BACKEND_URL = KURASTORAGE_BACKENDURL;
export const BACKEND_URL = KURASTORAGE_BACKENDURL;
export const subscriptionBasicPriceId = "price_1MRZjTIwXirsfm9gbYrBwdO5";
export const subscriptionMiniPriceId = "price_1MRZffIwXirsfm9gPjWau3HS";
export const loginPageLink = `https://authsso.spaceeight.net/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = `https://authsso.spaceeight.net/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const kuraStorageLink = `https://kura-storage.spaceeight.net`;
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';
export const TEXTAUDIOTOOLSNODE_URL = 'https://dokmp19-b2.spaceeight.net';


function App() {

    const [store, dispatch] = useStore();
    console.log('store-App.tsx', store);
    const { 
        isAuth, 
        gLoading, 
        srvLoading,
        metaLoading,
        isAdmin, 
        isAllApis,
      } = store.uiStore;
      const { isStripeApi } = store.stripeData;
      const { isServiceBucketsApi } = store.serviceBucketsStore;
    

      const userLoginEmail = localStorage.getItem("email")
      ? localStorage.getItem("email")
      : "";
    
      useEffect(() => {
        if (isServiceBucketsApi && isStripeApi) {
          dispatch('SET_ISALLAPIS', true);
        } else {
          dispatch('SET_ISALLAPIS', false);
        }
      }, [isStripeApi, isServiceBucketsApi]);
    
    
      useEffect(() => {
        const lsCustomerEmail = localStorage.getItem('customerEmail');
        
        if (userLoginEmail && lsCustomerEmail) {
          const token = localStorage.getItem('token');
        
          getCustomerDataSubscriptions(token, userLoginEmail)
            .then(result => {
              console.log('get sub result', result);
    
              if (result.data.length > 0) {
                const subs = result.data;
                // dispatch('SET_SUBSCRIPTIONS', subs);
    
                const remeetSubs = subs.filter(sub => {
                  return sub.metadata.service === 'kura-storage';
                })
    
                dispatch('SET_SUBSCRIPTIONS', remeetSubs);
              }
    
            })
            .catch(err => {
              console.log('get sub error', err);
            });
        }
      }, [userLoginEmail]);


      let routesBody;

      if (isAuth) {
        routesBody = (
          <div>
            <Bucket />
    
            <Routes>
    
              {/* <Route path="privacypolicy" 
                element={<PrivacyPolicy />} 
              />
              <Route path="termsofuse" 
                element={<TermsOfUse />} 
              />
              <Route path="about" 
                element={<AboutPage />} 
              />
    
              <Route path="share-process" 
                element={<ShareProcess />} 
              />
    
              <Route path="contact" 
                element={<Contact />} 
              /> */}
    
              {/* {bucketName && isAllApis && (
                <>
                  <Route path="/userinfo" 
                    element={<UserInfo />} 
                  />
    
                  <Route path="/" 
                    element={<BucketObjects />} 
                  />
      
                  <Route path="/sizemanage" 
                    element={<SizeManagePage />} 
                  />
    
                  <Route path="/subscription/prices"
                    element={<Prices />}
                  />

                  <Route path="/subscription/subscribe"
                    element={<Subscribe />}
                  />
                      
                  <Route path="/subscription/change-plan"
                    element={<ChangePlan />}
                  />
    
                  <Route path="/subscription/account"
                    element={<Account />}
                  />
    
                  <Route path="/subscription/cancel"
                    element={<Cancel />}
                  />
                </>
              )} */}
              
              <Route path="/userinfo" 
                element={<UserInfo />} 
              />

              <Route path="termsofuse" 
                element={<TermsOfUse />} 
              />
              <Route path="privacypolicy" 
                element={<PrivacyPolicy />} 
              />
              <Route path="about" 
                element={<AboutPage />} 
              />
    
    
              <Route path="/audio-transcriber" 
                element={<TranscribeAudio />} 
              />

              <Route path="/voice-generator" 
                element={<VoiceGenerator2 />} 
              />

              <Route path="/voice-generator-noen" 
                element={<VoiceGenerator2NoEn />} 
              />

              <Route path="/answer-finder" 
                element={<AnswerFinder />} 
              />

              <Route path="/video-to-audio" 
                element={<VideoToAudio />} 
              />

              <Route path="/audio-editor" 
                element={<AudioEditor />} 
              />

              <Route path="*" 
                element={<TranscribeAudio />} 
              />
        
    
              {/* <Route path="*" 
                element={<NotPageFound />} 
              /> */}
    
            </Routes>
    
          </div>
    
        )
      }
      else {
        routesBody = (
        <div>
          <Routes>
            
            <Route path="termsofuse" 
              element={<TermsOfUse />} 
            />
            <Route path="privacypolicy" 
              element={<PrivacyPolicy />} 
            />
            <Route path="about" 
              element={<AboutPage />} 
            />

            
            <Route path="/audio-transcriber" 
              element={<TranscribeAudio />} 
            />

            <Route path="/voice-generator" 
              element={<VoiceGenerator2 />} 
            />

            <Route path="/voice-generator-noen" 
                element={<VoiceGenerator2NoEn />} 
            />
            
            <Route path="/answer-finder" 
              element={<AnswerFinder />} 
            />

            <Route path="/video-to-audio" 
              element={<VideoToAudio />} 
            />

            <Route path="/audio-editor" 
              element={<AudioEditor />} 
            />

            <Route path="*" 
              element={<TranscribeAudio />} 
            />
          </Routes>
        </div>
        )
      }



    return (
        <Fragment>
            <BrowserRouter>
                <Layout>
                    {(gLoading || srvLoading || metaLoading) && (
                        <div>
                        <Backdrop 
                            zIndex={'500'}
                            onCancel={() => { 
                            if (!gLoading) {
                                // setClickedObjectKeyHandler(''); 
                            }
                            }}
                        />
                        <span className='gLoader'>
                            <Loader />
                        </span>
                        </div>
                    )}

                    {/* {isStripeApi && (
                        <div>
                        {routesBody}
                        </div>
                    )}

                    {!isStripeApi && (
                        <div>
                        <NotPageFound />
                        </div>
                    )} */}
                    <div>
                        {routesBody}
                    </div>


                    {/* <GetWindowData /> */}
                    {/* <GetDeferredPrompt /> */}
                    <HeadMetaTag />
            
                    <GetAuth />
                    <AuthCheck />
                    
                    <div style={{display:'none'}}>
                        <GetLoginUsers />
                    </div>
                 
                    <LoadParamFile />

                    {isAuth && (
                        <div>
                            {/* <Register /> */}
                            {/* <GetAllObjects /> */}
                            {/* <GetObjectMetadata /> */}
                            {/* <GetReservedPrefixes /> */}
                            <GetSubscriptionData />

                            {/* <FileUploadSocket /> */}

                            <ServiceBuckets />

                            {/* <GetShareInfo /> */}
                            <SetUserNameData />

                            <UserSocketConnect />
                            {/* <ToolsDataSocket /> */}
                        </div>
                        )}
                </Layout>
            </BrowserRouter>
        </Fragment>
    );
}

export default App;
