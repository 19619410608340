import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { AudioVisualizer } from 'react-audio-visualize';
import Crunker from "crunker";
import numeral from "numeral";
import download from 'downloadjs';
// import _ from "lodash";

import AudioConvertToMP3FileUpload from './AudioConvertToMP3FileUpload.jsx';
import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
// import { changeAmplitude } from "../AudioSlicer/audio-helper.ts"
// import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';
import { 
  audioBufferToMP3Blob,
  sleep,
 } from '../audio-editor-util.js';

import { marks } from '../../../utils/marks.jsx';
import "./AudioConvertToMP3.css"



const selectList = [
  { name: "File1 + File2", value: [0, 1] },
  { name: "File2 + File1", value: [1, 0] },
];

const mp3ConvertedAudioId = 'mp3-converted-audio';


function AudioConvertToMP3Convert(props) {
  const {
    audioFiles,
    combinedFile,
    setCombinedFile,
    playSound,
    pauseSound,
    restartSound,
    getAudioFileData,
    widthScaleFactor,
    setWidthScaleFactor,
  } = props;

  const [t] = useTranslation("translation");

  // const visualizerRef = useRef(null)

  const [store, dispatch] = useStore();

  const [combinedAudioEl, setCombinedAudioEl] = useState();
  const [combinedCurrentTime, setCombinedCurrentTime] = useState(0);
  const [displayType, setDisplayType] = useState('player');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [changeFactor, setChangeFactor] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  const createMP3Handler = async (audioBuffer) => {
    try {
      setIsLoading(true);
      // console.log(audioBuffer)

      const blob = await audioBufferToMP3Blob(audioBuffer);

      const mp3File = new File([blob], `converted-${Date.now()}.mp3`, { type: 'audio/mp3' });

      const mp3FileData = await getAudioFileData(mp3File);
      
      setCombinedFile(mp3FileData);

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };

  const changeCombinedCurrentTime = (event) => { 
    setCombinedCurrentTime(event.target.currentTime);
  }

  const switchDisplayFormHandler = (type) => {
      setDisplayType(type);
  }

  const downloadCombinedFile = (file) => {
    // console.log(file)
    download(file, file.name, file.type);
  };

  const changeFactorInputHandler = (event) => {
    const changeFactor = Number(event.target.value)
    if (!(changeFactor >= 0.1 && changeFactor <= 3)) {
      return;
    }
    setChangeFactor(changeFactor);
  };


  useMemo(() => {
    if (combinedFile) {
      setCombinedAudioEl(
        <audio className='audioConvertToMP3AudioEl'
          id={mp3ConvertedAudioId}
          src={URL.createObjectURL(combinedFile.file)}
          controls
          onTimeUpdate={changeCombinedCurrentTime}
        />
      );
    }
  }, [combinedFile]);



  const containerHeight = 100;
  let waveHeight = 0;

  if (combinedFile) {
    waveHeight = combinedFile.bufferMax * containerHeight;
  }


  let audioAmpChangerChangeBody;

  if (audioFiles.length >= 1) {
    audioAmpChangerChangeBody = (
      <div>
        <div className='audioConvertToMP3Section'>
          <button
            className='audioConvertToMP3ConvertButton'
            disabled={isLoading}
            onClick={() => { 
              createMP3Handler(audioFiles[0].audioBuffer);
            }}
          >
            Convert to mp3
          </button>
        </div>

        {isLoading && (
          <div><Loader /></div>
        )}
        
        {combinedFile && (
          <div className='audioConvertToMP3Section'>
            <div style={{display: 'none'}}>
              {combinedAudioEl}
            </div>
            <div>
              <div>
                <span>
                  <strong>
                    Converted audio{' '}
                  </strong>
                  <span>
                    ({numeral(combinedFile.file.size).format('0.0 b')})
                    {' '}
                  </span>
                </span>
                {/* <span>
                  <button className='audioConvertToMP3ListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('wave');
                    }}
                  >
                    {marks.barChartMark} (wave){' '}
                  </button>
                  <button className='audioConvertToMP3ListControlButton'
                    onClick={() => {
                      switchDisplayFormHandler('player');
                    }}
                  >
                    {marks.playMark} (player)
                  </button>
                </span> */}
              </div>
              {/* {displayType} */}
            </div>
            
            {displayType === 'player' && (
              <div style={{ padding: "1rem"}}>
              {combinedAudioEl}
              </div>
            )}
            {/* {displayType === 'wave' && (
              <div>
                <div
                  style={{
                    height: containerHeight,
                    paddingTop: (containerHeight - waveHeight) / 2,
                    paddingBottom: (containerHeight - waveHeight) / 2,
                    border: "0.1px solid lightgray",
                  }}
                >
                  <AudioVisualizer
                    // ref={visualizerRef}
                    // ref={document.getElementById(audioFiles[0].id)}
                    blob={combinedFile.file}
                    width={combinedFile.audioBuffer.duration * 1 * widthScaleFactor}
                    height={combinedFile.bufferMax * containerHeight}
                    barWidth={1}
                    gap={0}
                    // barColor={'#f76565'}
                    barColor={'red'}
                    barPlayedColor={'gray'}
                    currentTime={combinedCurrentTime}
                    // currentTime={element.id === currentTime.id ? currentTime.currentTime : 0}
                  />
                </div>
                <div className='audioConvertToMP3ListControl'>
                  <button className='audioConvertToMP3ListControlButton'
                    title={'play'}
                    onClick={(event) => { playSound(event, mp3ConvertedAudioId) }}
                  >
                    {marks.playMark}
                  </button>
                  <button className='audioConvertToMP3ListControlButton'
                    title={'pause'}
                    onClick={(event) => { pauseSound(event, mp3ConvertedAudioId) }}
                  >
                    {marks.pauseMark}
                  </button>
                  <button className='audioConvertToMP3ListControlButton'
                    title={'restart'}
                    onClick={(event) => { restartSound(event, mp3ConvertedAudioId) }}
                  >
                    {marks.shareMark}
                  </button>
                  <span>
                    {numeral(combinedCurrentTime).format('00:00')}
                  </span>
                </div>
              </div>
            )} */}

            <div className=''>
              <button className='btnBase'
                onClick={() => {
                  downloadCombinedFile(combinedFile.file);
                }}
              >
                Download file {marks.downloadMark}
              </button>
              <button className='btnBase'
                onClick={() => {
                  setShowUploadModal(true);
                  // createCombinedTextFile(context, question, output);
                }}
              >
                Upload file {marks.uploadMark} 
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        {audioAmpChangerChangeBody}
      </div>

      {showUploadModal && (
        <div>
          <AudioConvertToMP3FileUpload 
            uploadFile={combinedFile.file}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
    </Fragment>
  )
}

export default AudioConvertToMP3Convert;