import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Player from './player.tsx';
import FilePicker from './file.tsx';
import Icon from './icon.tsx';
import {
  isAudio, 
  readBlobURL, 
  // download, 
  rename,
} from './utils.ts';
import { decodeAudioBuffer, sliceAudioBuffer } from './audio-helper.ts';
// import encode from './worker-client.ts';
import './index.less';
import {
  downloadIcon,
  musicIcon,
  pauseIcon,
  playIcon,
  replayIcon,
  spinIcon,
} from './icons/index.ts';
import { useClassicState } from './hooks.ts';
import { SUpportedFormat } from './types.ts';


import encode2 from './worker-client.ts';
// import encodeWav from './encoder/wav.ts';
// import encodeLame from './encoder/lame.ts';
// import { serializeAudioBuffer } from './audio-helper.ts';

import AudioSlicerControl from './AudioSlicerControl.jsx';
import AudioSlicerSelectFile from './AudioSlicerSelectFile.jsx';
import AudioSlicerReslult from './AudioSlicerResult.jsx';

import { marks } from '../../../utils/marks';
import './AudioSlicer.css';


export default function AudioSlicer() {
  const [state, setState] = useClassicState<{
    file: File | null;
    decoding: boolean;
    audioBuffer: AudioBuffer | null;
    paused: boolean;
    startTime: number;
    endTime: number;
    currentTime: number;
    processing: boolean;
    slicedAudioFile: File | null;
  }>({
    file: null,
    decoding: false,
    audioBuffer: null,
    paused: true,
    startTime: 0,
    endTime: Infinity,
    currentTime: 0,
    processing: false,
    slicedAudioFile: null,
  });


  // console.log(state)


  const handleFileChange = async (file: File) => {
    if (!isAudio(file)) {
      // alert('请选择合法的音频文件');
      alert('Not audio file');
      return;
    }

    setState({
      file,
      paused: true,
      decoding: true,
      audioBuffer: null,
    });

    const audioBuffer = await decodeAudioBuffer(file);

    setState({
      // paused: false,
      decoding: false,
      audioBuffer,
      startTime: 0,
      currentTime: 0,
      endTime: audioBuffer.duration / 2,
    });
  };

  const handleStartTimeChange = (time: number) => {
    setState({
      startTime: time,
    });
  };

  const handleEndTimeChange = (time: number) => {
    setState({
      endTime: time,
    });
  };

  const handleCurrentTimeChange = (time: number) => {
    setState({
      currentTime: time,
    });
  };

  const handleEnd = () => {
    setState({
      currentTime: state.startTime,
      // paused: false,
      paused: true,
    });
  };

  const handlePlayPauseClick = () => {
    setState({
      paused: !state.paused,
    });
  };

  const handleReplayClick = () => {
    setState({
      currentTime: state.startTime,
      paused: false,
    });
  };

  const handleEncode = async (type: SUpportedFormat) => {
    try {
      const {
        startTime, endTime, audioBuffer, file,
      } = state;
      if (!audioBuffer || !file) return;
  
      const { length, duration } = audioBuffer;
  
      const audioSliced = sliceAudioBuffer(
        audioBuffer,
        Math.floor(length * startTime / duration),
        Math.floor(length * endTime / duration),
      );
  
      setState({
        processing: true,
      });
  
      const slicedBlob = await encode2(audioSliced, type)
      const slicedFile = new File([slicedBlob], `sliceAudio-${Date.now()}.wav`, {type: 'audio/wav'});
      
      setState({
        slicedAudioFile: slicedFile
      });

      setState({
        processing: false,
      });

      // encode2(audioSliced, type)
      //   .then(result => {
      //     console.log('result', result);
      //     const file = new File([result], `slicedAudio-${Date.now}.wav`, {type: type});
  
      //     setState({
      //       slicedAudioFile: file
      //     });
      //     return readBlobURL(result)
      //   })
      //   .then((url) => {
      //     console.log(url);
      //     // download(url, rename(file.name, type));
      //   })
      //   .catch((e) => console.error(e))
      //   .then(() => {
      //     setState({
      //       processing: false,
      //     });
      //   });
    } catch(err) {
      console.log(err);

      setState({
        processing: false,
      });
      // throw err;
    }
    // const {
    //   startTime, endTime, audioBuffer, file,
    // } = state;
    // if (!audioBuffer || !file) return;

    // const { length, duration } = audioBuffer;

    // const audioSliced = sliceAudioBuffer(
    //   audioBuffer,
    //   Math.floor(length * startTime / duration),
    //   Math.floor(length * endTime / duration),
    // );

    // setState({
    //   processing: true,
    // });

    // const slicedBlob = await encode2(audioSliced, type)

    // encode2(audioSliced, type)
    //   .then(result => {
    //     console.log('result', result);
    //     const file = new File([result], `slicedAudio-${Date.now}.wav`, {type: type});

    //     setState({
    //       slicedAudioFile: file
    //     });
    //     return readBlobURL(result)
    //   })
    //   .then((url) => {
    //     console.log(url);
    //     // download(url, rename(file.name, type));
    //   })
    //   .catch((e) => console.error(e))
    //   .then(() => {
    //     setState({
    //       processing: false,
    //     });
    //   });
  };

  const displaySeconds = (seconds: number) => `${seconds.toFixed(2)}s`;

  return (
    <div className="SlicerContainer">
      <div className='audioSlicerSection'>
        <AudioSlicerSelectFile 
          handleFileChange={handleFileChange}
          file={state.file}
        />
      </div>

      {
        state.audioBuffer || state.decoding ? (
          <div>
            {/* <h2 className="app-title">Audio Cutter</h2> */}

            {
              state.decoding ? (
                <div className="player player-landing">
                  DECODING...
                </div>
              ) : (
                <Player
                  audioBuffer={state.audioBuffer!}
                  blob={state.file!}
                  paused={state.paused}
                  startTime={state.startTime}
                  endTime={state.endTime}
                  currentTime={state.currentTime}
                  onStartTimeChange={handleStartTimeChange}
                  onEndTimeChange={handleEndTimeChange}
                  onCurrentTimeChange={handleCurrentTimeChange}
                  onEnd={handleEnd}
                />
              )
            }
            <div className='audioSlicerSection'>
              <div className="controllers">
                {/* <FilePicker className="ctrl-item" onPick={handleFileChange}>
                  <Icon icon={musicIcon} />
                  load-file
                </FilePicker> */}
                {!state.decoding && (
                  <AudioSlicerControl 
                    setState={setState}
                    startTime={state.startTime}
                    currentTime={state.currentTime}
                  />
                )}

                {/* <button
                  type="button"
                  className="ctrl-item"
                  title="Play/Pause"
                  onClick={handlePlayPauseClick}
                >
                  {state.paused ? marks.playMark : marks.pauseMark}
                </button> */}
                {/* <button
                  type="button"
                  className="ctrl-item"
                  title="Replay"
                  onClick={handleReplayClick}
                >
                  {marks.shareMark}
                </button> */}

                {/* <div className="dropdown list-wrap">
                  <button
                    type="button"
                    className="ctrl-item"
                  >
                    <Icon icon={state.processing ? spinIcon : downloadIcon} />
                  </button>
                  {
                    !state.processing && (
                      <ul className="list">
                        <li>
                          <button
                            type="button"
                            onClick={() => handleEncode('wav')}
                          >
                            Wav
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            // onClick={() => handleEncode('mp3')}
                            data-type="mp3"
                          >
                            MP3
                          </button>
                        </li>
                      </ul>
                    )
                  }
                </div> */}

                {/* {!state.processing && (
                  <button
                    onClick={() => handleEncode('wav')}
                  >
                    (Wav-slice-audio)
                  </button>
                )} */}

              </div>
              <div className="controllers">
                {
                  Number.isFinite(state.endTime)
                  && (
                  <span className="seconds">
                    Select
                    {' '}
                    <span className="seconds-range">
                      {
                      displaySeconds(state.endTime - state.startTime)
                    }
                    </span>
                    {' '}
                    of
                    {' '}
                    <span className="seconds-total">
                      {
                      displaySeconds(state.audioBuffer?.duration ?? 0)
                    }
                    </span>
                    {' '}
                    (from
                    {' '}
                    <span className="seconds-start">
                      {
                      displaySeconds(state.startTime)
                    }
                    </span>
                    {' '}
                    to
                    {' '}
                    <span className="seconds-end">
                      {
                      displaySeconds(state.endTime)
                    }
                    </span>
                    )
                  </span>
                  )
                }
              </div>
            </div>
            {!state.processing && (
              <div className='audioSlicerSection'>
                
                <button className='audioSlicerSliceButton'
                  onClick={() => handleEncode('wav')}
                  // onClick={() => handleEncode('mp3')}
                >
                  Slice Audio
                </button>
                </div>
              )}
          </div>
        ) : (
          <div className="landing">
            {/* <h2>Audio Cutter</h2>
            <FilePicker onPick={handleFileChange}>
              <div className="file-main">
                <Icon icon={musicIcon} />
                Select music file
              </div>
            </FilePicker> */}
          </div>
        )
      }

      <AudioSlicerReslult 
        slicedAudioFile={state.slicedAudioFile}
      />

    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('main'));
