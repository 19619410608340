import { Fragment, useEffect, useRef, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";

import AudioAmpChanger from './AudioAmpChanger/AudioAmpChanger.jsx';
import AudioSlicer from './AudioSlicer/AudioSlicer.tsx';
import AudioCombiner from './AudioCombiner/AudioCombiner.jsx';
import AudioConvertToMP3 from './AudioConvertToMP3/AudioConvertToMP3.jsx';
// import { convert } from 'video-to-audio';

import { useStore } from '../../hook-store/store.js';

import './AudioEditor.css';

const audioEditorApps = [
  { name: 'Audio Slicer', value: 'audioSlicer' },
  { name: 'Audio Combiner', value: 'audioCombiner' },
  { name: 'Audio Amplitude Changer', value: 'audioAmpChanger' },
  { name: 'Compress Audio File', value: 'audioConvertToMP3' },
];

function AudioEditor(props) {

  const [t] = useTranslation("translation");


  const [store, dispatch] = useStore();
  const { 
    paramFileDataList
  } = store.uiStore;

  const [selectedApp, setSelectedApp] = useState('audioSlicer');

  useEffect(() => {
    if (paramFileDataList && paramFileDataList.files?.length > 0) {
      
      if (paramFileDataList.openApp === 'audioCombiner') {
        setSelectedApp('audioCombiner')
      }
      if (paramFileDataList.openApp === 'audioAmpChanger') {
        setSelectedApp('audioAmpChanger')
      }
      if (paramFileDataList.openApp === 'audioConvertToMP3') {
        setSelectedApp('audioConvertToMP3')
      }
    }
  },[paramFileDataList]);

  let audioEditorBody;

  audioEditorBody = (
    <div>
        <div className='audioEditorSection'>
          <div className='audioEditorTitle'>
            Audio Editor
          </div>
        </div>
        <div className='audioEditorSection'>
          <div>
            Select Audio Editor App
          </div>
          <div>
            <select className='selectBase'
              // name="pets" 
              // id="pet-select"
              defaultValue={audioEditorApps[0].value}
              value={selectedApp}
              onChange={(event) => { 
                setSelectedApp(event.target.value)
              }}
            >
              {audioEditorApps.map(element => {
                return (
                  <option value={element.value}>
                    {element.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div>
          {selectedApp === 'audioSlicer' && (
            <div>
              <AudioSlicer />
            </div>
          )}
          {selectedApp === 'audioCombiner' && (
            <div>
              <AudioCombiner />
            </div>
          )}
          {selectedApp === 'audioAmpChanger' && (
            <div>
              <AudioAmpChanger />
            </div>
          )}
          {selectedApp === 'audioConvertToMP3' && (
            <div>
              <AudioConvertToMP3 />
            </div>
          )}
        </div>
    </div>
  );

  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
          style={{width:"310px", height:"70px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
      style={{width:"460px", height:"95px", border:"none"}}  
      src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
          style={{width:"610px", height:"135px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
      />
    );
  }

  return (
    <Fragment>
      <div>
      <div 
        style={{
            display: 'flex',
            justifyContent: "center",
            paddingTop: "1rem",
            paddingBottom: "2rem"
        }}
        >
          {bannerElement}
      </div>
      <div>
        {audioEditorBody}
      </div>
      </div>
    </Fragment>
  )
}

export default AudioEditor;