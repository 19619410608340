import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// import ImagePhotoAuthInfo from '../../UploadImage/ImagePhotoAuthInfo';
import Loader from '../Loader/Loader';

import { useStore } from '../../hook-store/store';
import { s3fileUpload2 } from '../../utils/file-upload-util/bucket-upload-util';
import { mimeTypes } from '../../utils/file/mime-type';
import { BACKEND_URL } from '../../App';

import { marks } from '../../utils/marks';
// import classes from './BgRemoveUpload.module.css';

const FilesUploadAction = (props) => {
  const { 
    uploadFiles,
    uploadApp,
  } = props

  const [t] = useTranslation('translation')

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    // selectedPrefix,
  } = store.bucketObjectStore;
  const { gLoading, isAuth } = store.uiStore;
  const { serviceBucketsDataList } = store.serviceBucketsStore;
  const { mlSpeechInputFileName} = store.mlSpeechStore;

  const [kuraStorageBucketData, setKuraStorageBucketData] = useState();
  const [resultMessage, setResultMessage] = useState('');
  const [uploadNameFile, setUploadNameFile] = useState();
  const [isLoading, setIsLoading] = useState(false);


  let canUpload = false;

  if (isAuth && kuraStorageBucketData && uploadFiles) {
    canUpload = true;
  }

  // console.log(uploadFiles)

  // console.log('bucketName', bucketName, canUpload, isAuth, kuraStorageBucketData, uploadFiles);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {

    const kuraStorageBucketData = serviceBucketsDataList.find(element => {
      return element.bucket === localStorage.getItem('userId');
    });
  
    let bucketName = '';
    
    if (kuraStorageBucketData) {
      //@ts-ignore
      dispatch('SET_BUCKETNAME', kuraStorageBucketData.bucket);
      setKuraStorageBucketData(kuraStorageBucketData);
    }

  },[serviceBucketsDataList]);


  useEffect(() => {
    if (uploadFiles?.length > 0 && !uploadNameFile) {
      createUploadNameFile(uploadFiles[0], uploadApp);
    }
  },[uploadFiles]);


  const createFileName = (file, uploadApp) => {
    let name;

    if (uploadApp === 'audioTranscriber') {
      if (file.type === 'text/plain') {
        name = `audio-transcript-${Date.now()}.txt`;
      }

      if (file.type?.split('/')[0] === 'audio') {
        let ext = 'mp3';

        if (mlSpeechInputFileName) {
          const fileSplit = mlSpeechInputFileName.split('.');
          const fileExt = fileSplit.pop();
          ext = fileExt;
        }

        for (const mExt in mimeTypes) {
          const matchMime = mimeTypes[mExt] === file.type;

          if (matchMime) {
            ext = mExt;
          };

          console.log(matchMime)
        }

        name = `audio-transcriber-input-${Date.now()}.${ext}`;
      }

      if (file.type?.split('/')[0] === 'video') {
        let ext = 'mp4';

        if (mlSpeechInputFileName) {
          const fileSplit = mlSpeechInputFileName.split('.');
          const fileExt = fileSplit.pop();
          ext = fileExt;
        }
        
        for (const mExt in mimeTypes) {
          const matchMime = mimeTypes[mExt] === file.type;

          if (matchMime) {
            ext = mExt;
          };

          console.log(matchMime)
        }
        name = `audio-transcriber-input-${Date.now()}.${ext}`;
      }
    }

    if (uploadApp === 'voiceGenerator') {
      if (file.type === 'text/plain') {
        name = `voice-input-text-${Date.now()}.txt`;
      }

      if (file.type === 'audio/wav') {
        name = `output-voice-${Date.now()}.wav`;
      }

    }

    if (uploadApp === 'answerFinder') { 
      name = `answer-finder-${Date.now()}.txt`;
    }

    if (uploadApp === 'videoToAudio') {
      name = `converted-audio-${Date.now()}.${file.type.split('/')[1]}`
    }

    if (uploadApp === 'audioSlicer') {
      name = `slice-audio-${Date.now()}.${file.type.split('/')[1]}`
    }

    if (uploadApp === 'audioCombiner') {
      name = `combine-audio-${Date.now()}.${file.type.split('/')[1]}`
    }

    if (uploadApp === 'audioAmpChanger') {
      name = `amp-changed-audio-${Date.now()}.${file.type.split('/')[1]}`
    }

    if (uploadApp === 'audioConvertToMP3') {
      name = `converted-audio-${Date.now()}.${file.type.split('/')[1]}`
    }

    return name;
  };

  const createUploadNameFile = (file, uploadApp) => {
    const name = createFileName(file, uploadApp);

    if (!name) {
      return;
    }

    console.log(name);
    // throw new Error('error-error');

    const uploadFile = new File([file], name, {type: file.type});
    setUploadNameFile(uploadFile);
    console.log(uploadFile);
    return uploadFile;
  };

  const filesUploadHandler = async (path, files, uploadApp) => {
    try {
      // setIsLoading(true);
      setResultMessage('');

      //@ts-ignore
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileUpload: null,
      };


      // if (!file) {
      //   throw new Error('error occured');
      // }

      const file = files[0]

      console.log(file, uploadApp, file.type);
      // throw new Error('error-error')

      // const name = createFileName(file, uploadApp);

      // if (!name) {
      //   throw new Error('name is required');
      // }

      // console.log(name);
      // // throw new Error('error-error');

      // const uploadFile = new File([file], name, {type: file.type});

      // const uploadFile = await uploadFileCreate(curRender.currentSrc, name, file.type);
      
      // const files = [uploadFile];
      // console.log('file, files', file, files);
      // console.log(currentRenderFile);
      console.log(path, files);
      // throw new Error('error-error');

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 10) {
        throw new Error('Upload limit is 10 file');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;

      result = await s3fileUpload2(
        BACKEND_URL,
        localStorage.getItem('token'),
        path,
        [file],
        bucketName,
        dispatch,
        uploadApp,
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      // const addedList = topLevels.concat(result.data.data);
      
      //@ts-ignore
      // dispatch('SET_TOPLEVELS', addedList);


      // //@ts-ignore
      // dispatch('SET_OBJECTACTION', {
      //   actionType: 'file-upload',
      //   delete: false,
      //   add: true,
      //   result: resultsForAction,
      // });

      // //@ts-ignore
      // dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      //@ts-ignore
      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setResultMessage('upload success');

      setTimeout(() => {
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      // showUploadMoadlHandler(false);
      // setIsLoading(false);
      //@ts-ignore
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      // setSelectedFiles([]);
      // setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      
      // //@ts-ignore
      // dispatch('SET_ISUPLOADFAILED', true);

      //@ts-ignore
      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        //@ts-ignore
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setResultMessage('upload failed');
      
      // setIsLoading(false);
      //@ts-ignore
      dispatch('SET_GLOADING', false);
    }

  };


  let filesUploadActionBody

  filesUploadActionBody = (
    <div >
      {canUpload && (
        <div>
          {uploadNameFile && (
            <div>
              <div>
                {uploadNameFile.name}
              </div>
              <br/>

              <div>
                <button className='iopaintBtnBase'
                  disabled={isLoading}
                  onClick={() => { 
                    filesUploadHandler(
                      '', 
                      [uploadNameFile],
                      uploadApp,
                    );
                  }}
                >
                  {/* {t('guideImage12', 'Upload')} */}
                  upload {marks.uploadMark}
                </button>
              </div>
            </div>
          )}


        </div>
      )}
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {resultMessage && (
        <div>
          <strong>{resultMessage}</strong>
        </div>
      )}

      {/* {!canUpload && (
        <div>
          <ImagePhotoAuthInfo />
        </div>
      )} */}
    </div>
  )

  return (
    <Fragment>
      <div>{filesUploadActionBody}</div>
    </Fragment>
  )
}

export default FilesUploadAction;
