import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";

import AboutAuth from '../UI/AboutAuth';
// import Backdrop from '../Backdrop/Backdrop';
// import Modal from '../Modal/Modal';
// import FilesUploadAction from '../../FileUploadAction/FilesUploadAction';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';

import { kuraStorageLink } from '../../App';
// import './VoiceFileUpload.css';

function UploadedFileUseContent(props) {
  const { 
    setShowModalUseUploaded,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store-App.tsx', store);
  const { isAuth } = store.uiStore;
  

  let answerFinderUseUploadBody;

  answerFinderUseUploadBody = (
    <div>
      <div>
        {isAuth && (
          <div>
            <a href={`${kuraStorageLink}`}
              target="_blank" rel="noopener noreferrer"
            >
              {t('uploadedFile01' ,'go to')}
              {' '}
              Kura Storage 
              {' '}
              {t('uploadedFile02' ,'to select file')}
              {' '}{marks.externalLinkMark}
            </a>
          </div>
        )}
        {!isAuth && (
          <div className='voiceFileUploadSection'>
            <AboutAuth />
          </div>
        )}
      </div>
    </div>
  )


  
  return (
    <Fragment>
      {answerFinderUseUploadBody}
    </Fragment>
  );
}

export default UploadedFileUseContent;
