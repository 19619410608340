import { sleep } from "../../utils/ui/ui-util";
var lamejs = require("lamejs");

export const audioBufferToMP3Blob = async (audioBuffer) => {
  try {
    // console.log(audioBuffer)
    await sleep(3000);
    
    var mp3Data = [];

    const numberOfChannels = audioBuffer.numberOfChannels;

    if (numberOfChannels >= 2) {
      var mp3encoder = new lamejs.Mp3Encoder(
        numberOfChannels,
        audioBuffer.sampleRate,
        128
      ); // 44100 is replaced. It needs the real AudioBuffer sample rate. In my test case 48000.

      const [left, right] = [
        audioBuffer.getChannelData(0),
        audioBuffer.getChannelData(1),
      ];

      // The transformed data, this is what you will pass to lame instead
      // If you are sure to use a Float32Array you can skip this and use [left, right] const.
      const l = new Float32Array(left.length);
      const r = new Float32Array(right.length);

      //Convert to required format
      for (var i = 0; i < left.length; i++) {
        l[i] = left[i] * 32767.5;
        r[i] = right[i] * 32767.5;
      }

      const sampleBlockSize = 1152; //can be anything but make it a multiple of 576 to make encoders life easier

      for (var i = 0; i < l.length; i += sampleBlockSize) {
        const leftChunk = l.subarray(i, i + sampleBlockSize);
        const rightChunk = r.subarray(i, i + sampleBlockSize);

        var mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);

        if (mp3buf.length > 0) {
          mp3Data.push(mp3buf);
        }
      }
      var mp3buf = mp3encoder.flush(); //finish writing mp3

      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    if (numberOfChannels === 1) {
      var mp3encoder = new lamejs.Mp3Encoder(
        numberOfChannels,
        audioBuffer.sampleRate,
        128
      ); // 44100 is replaced. It needs the real AudioBuffer sample rate. In my test case 48000.

      const [left] = [audioBuffer.getChannelData(0)];

      // The transformed data, this is what you will pass to lame instead
      // If you are sure to use a Float32Array you can skip this and use [left, right] const.
      const l = new Float32Array(left.length);
      // const r = new Float32Array(right.length);

      //Convert to required format
      for (var i = 0; i < left.length; i++) {
        l[i] = left[i] * 32767.5;
        // r[i] = right[i]*32767.5;
      }

      const sampleBlockSize = 1152; //can be anything but make it a multiple of 576 to make encoders life easier

      for (var i = 0; i < l.length; i += sampleBlockSize) {
        const leftChunk = l.subarray(i, i + sampleBlockSize);
        // const rightChunk = r.subarray(i, i + sampleBlockSize);

        var mp3buf = mp3encoder.encodeBuffer(leftChunk);

        if (mp3buf.length > 0) {
          mp3Data.push(mp3buf);
        }
      }
      var mp3buf = mp3encoder.flush(); //finish writing mp3

      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    const blob = new Blob(mp3Data, { type: "audio/mp3" });

    return blob;

  } catch (err) {
    console.log(err);
    throw err;
  }
};

export function createBlankAudioBuffer(channelNum, duration, sampleRate) {
  const newBuffer = new AudioContext().createBuffer(
    channelNum,
    parseInt(duration * sampleRate),
    sampleRate,
  );

  return newBuffer

}