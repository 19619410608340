import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import download from 'downloadjs';

import AnswerFinderFileUpload from './FileControl/AnswerFinderFileUpload';
import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';
import UploadedFileUseContent from '../UploadedFileUseContent/UploadedFileUseContent';
import TranslateProgress from './TranslateProgress';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';
import './AnswerFinder.css'

const defaultContext = "The Amazon rainforest (Portuguese: Floresta Amazônica or Amazônia; Spanish: Selva Amazónica, Amazonía or usually Amazonia; French: Forêt amazonienne; Dutch: Amazoneregenwoud), also known in English as Amazonia or the Amazon Jungle, is a moist broadleaf forest that covers most of the Amazon basin of South America. This basin encompasses 7,000,000 square kilometres (2,700,000 sq mi), of which 5,500,000 square kilometres (2,100,000 sq mi) are covered by the rainforest. This region includes territory belonging to nine nations. The majority of the forest is contained within Brazil, with 60% of the rainforest, followed by Peru with 13%, Colombia with 10%, and with minor amounts in Venezuela, Ecuador, Bolivia, Guyana, Suriname and French Guiana. States or departments in four nations contain. The Amazon represents over half of the planet's remaining rainforests, and comprises the largest and most biodiverse tract of tropical rainforest in the world, with an estimated 390 billion individual trees divided into 16,000 species.";
const defautQuestion = "What proportion of the planet's rainforests are found in the Amazon?"

function AnswerFinder() {

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { paramFileData } = store.uiStore;
  // const { mlSpeechText } = store.mlSpeechStore;

  // console.log('store-answer-finder', store)
  
  // Model loading
  const [ready, setReady] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [progressItems, setProgressItems] = useState([]);
  const [downloadData, setDownloadData] = useState(0);
  const [combinedFile, setCombinedFile] = useState(); 

  // Inputs and outputs
  const [context, setContext] = useState('');
  const [question, setQuestion] = useState('');
  const [output, setOutput] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showModalUseUploadedFile, setShowModalUseUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Create a reference to the worker object.
  const trWorker = useRef(null);

  // We use the `useEffect` hook to setup the worker as soon as the `App` component is mounted.
  useEffect(() => {
    if (!trWorker.current) {
      // Create the worker if it does not yet exist.
      trWorker.current = new Worker(new URL('../../utils/common-worker.js', import.meta.url), {
        type: 'module'
      });
    }

    // Create a callback function for messages from the worker thread.
    const onMessageReceived = (e) => {
      // console.log(e.data)

      if (e.data.type === 'result') {
        setDisabled(false);
        // console.log(e.data)
        setOutput(e.data.data);
      }

      if (e.data.type === 'download') {
        setProgressItems(
          prev => prev.map(item => {
            // console.log(item, e.data.data)
            if (item.file === e.data.data.file) {
              return { ...item, progress: e.data.data.progress }
            }
            return { item };
          })
        );

        if (e.data.data.progress) {
          setDownloadData(e.data.data);
        }
      }
      // console.log(e.data);

      switch (e.data.data.status) {
        case 'initiate':
          // Model file start load: add a new progress item to the list.
          setReady(false);
          setProgressItems(prev => [...prev, e.data]);
          break;

        case 'progress':
          // Model file progress: update one of the progress items.
          setProgressItems(
            prev => prev.map(item => {
              if (item.file === e.data.file) {
                return { ...item, progress: e.data.progress }
              }
              return item;
            })
          );
          break;

        case 'done':
          // Model file loaded: remove the progress item from the list.
          setProgressItems(
            prev => prev.filter(item => item.file !== e.data.file)
          );
          break;

        case 'ready':
          // Pipeline ready: the worker is ready to accept messages.
          setReady(true);
          break;

        case 'update':
          // Generation update: update the output text.
          setOutput(e.data.output);
          break;

        case 'complete':
          // Generation complete: re-enable the "Translate" button
          setDisabled(false);
          break;

      }
    };

    // Attach the callback function as an event listener.
    trWorker.current.addEventListener('message', onMessageReceived);

    // Define a cleanup function for when the component is unmounted.
    return () => trWorker.current.removeEventListener('message', onMessageReceived);
  });


  useEffect(() => {
    if (paramFileData && paramFileData.openApp === 'answerFinder') {
      setParamFileHandler();
    }

  }, [paramFileData]);

  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const submitTask = () => {
    setDisabled(true);
    trWorker.current.postMessage({
      task: 'question-answering',
      // text: input,
      // src_lang: sourceLanguage,
      // tgt_lang: targetLanguage,
      context: context,
      question: question,
      elementIdToUpdate: "someid",
    });
  }

  const createCombinedTextFile = (context, question, output) => {

    const textToWrite = "context\r\n" + context + "\r\n\r\n"  
          + "Question\r\n" +  question + "\r\n\r\n" +
          "Answer\r\n" + output.answer + "\r\nScore\r\n" + output.score.toFixed(2);

    var textFileAsBlob = new Blob([textToWrite], {type:'text/plain'});
    var file = new File([textFileAsBlob], `question-answer-${Date.now()}.txt`, {type: "text/plain"});
    setCombinedFile(file);
    // download(file, file.name, file.type);
    return file;
  };

  const combinedTextDownload = (context, question, output) => {
    const file = createCombinedTextFile(context, question, output);
    download(file, file.name, file.type);
  }

  const setSampleHandler = () => {
    setContext(defaultContext);
    setQuestion(defautQuestion);
  };

  const setParamFileHandler = async () => {
    try {
      const files = [paramFileData.file];

      const fileUrl = URL.createObjectURL(files[0]);
      // const mimeType = files[0].type;

      const response = await fetch(fileUrl);

      const resData = await response.text();

      if (resData) {
        setContext(resData);
  
        dispatch('SET_PARAMFILEDATA', null);
      }


    } catch(err) {
      console.log(err);
    }
  };


  const loadTextFileHandler = async (event) => {
    try {
      const file = event.target.files[0]

      if (file.type !== 'text/plain') {
        return;
      }

      setIsLoading(true);

      
      const fileUrl = URL.createObjectURL(file);

      const result = await fetch(fileUrl);

      const resText = await result.text();

      if (resText) {
        setContext(resText);
      }
      
      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };

  let nearAnswerText;

  if (output?.answer && context.indexOf(output.answer) >= 0) {
    nearAnswerText = (
      <span>
        {context.substring(context.indexOf(output.answer)-100, context.indexOf(output.answer))} 
        {' '}
        <strong>
          {context.substring(context.indexOf(output.answer), context.indexOf(output.answer) + output.answer.length)}
        </strong>
        {' '}
        {context.substring(context.indexOf(output.answer) + output.answer.length, context.indexOf(output.answer) + output.answer.length + 100)}
      </span>
    )
  }

  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
          style={{width:"310px", height:"70px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
      style={{width:"460px", height:"95px", border:"none"}}  
      src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
          style={{width:"610px", height:"135px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
      />
    );
  }

  return (
    <Fragment>
      <div className='answerFinder'>
        <div 
          style={{
              display: 'flex',
              justifyContent: "center",
              paddingTop: "1rem",
              paddingBottom: "2rem"
          }}
          >
            {bannerElement}
        </div>

      <div className='answerFinderSection'>
        <div className='answerFinderTitle'>
          Answer Finder
        </div>
      </div>

        {/* <div className='language-container'>
          <LanguageSelector type={"Source"} defaultLanguage={"eng_Latn"} onChange={x => setSourceLanguage(x.target.value)} />
          <LanguageSelector type={"Target"} defaultLanguage={"fra_Latn"} onChange={x => setTargetLanguage(x.target.value)} />
        </div> */}
      <div className='answerFinderSection'>
        This page supports English only
      </div>

      <div className='answerFinderSection'>
        <div className='answerFinderContextLabels'>
          <label>
            <strong>
              Context
            </strong>
            {' '}
            {!context && !question && (
              <span
                onClick={setSampleHandler}
              >
                [use sample]
              </span>
            )}
          </label>

          <input 
            type='file'
            accept='text/plain'
            ref={hiddenFileSelect}
            style={{display:"none"}}
            onChange={loadTextFileHandler}
          />
          <button className="btnBase"
            style={{border:"0.5px solid gray", borderRadius: "4px"}}
            disabled={isLoading} 
            onClick={handleInputClick}
          >
            {marks.fileTextOMark}{' '}
            import text file
          </button>

          <button className="btnBase"
            style={{border:"0.5px solid gray", borderRadius: "4px"}}
            disabled={isLoading} 
            onClick={() => { setShowModalUseUploaded(true) }}
          >
            {marks.downloadMark} use uploaded file
          </button>
        </div>
        {showModalUseUploadedFile && (
          <div>
            <Backdrop 
              zIndex={'95'}
              onCancel={() => { 
                setShowModalUseUploaded(false);
              }}
            />
            <Modal
              onClose={() => {
                setShowModalUseUploaded(false);
              }}
            >
              <UploadedFileUseContent
                setShowModalUseUploaded={setShowModalUseUploaded}
              />
            </Modal>
          </div>
        )}

        <div 
        className='answerFinderContextContainer'
        >
          <textarea className='answerFinderContext'
            // style={{width: "40rem", maxWidth: "90%"}}
            value={context} 
            rows={7} 
            onChange={e => setContext(e.target.value)}
          >
          </textarea>
        </div>
      </div>
      <div className='answerFinderSection'>
        <label>
          <strong>
            Question
          </strong>
        </label>
        <div className='answerFinderQuestionContainer'>
          <textarea className='answerFinderQuestion'
            // style={{width: "40rem", maxWidth: "90%"}}
            value={question} 
            rows={2}
            onChange={e => setQuestion(e.target.value)}
          ></textarea>
        </div>
      </div>
      
      <div className='answerFinderSection'>
        <button className='btnBase'
          style={{ padding: "1rem", fontSize: "1.25rem", fontWeight: "bold" }}
          disabled={disabled || !context || !question} 
          onClick={submitTask}
        >
          Find answer
        </button>
      </div>
      
      {disabled && (
        <div>
          <Loader />
        </div>
      )}

      <div 
      // className='progress-bars-container'
      >
        {ready === false && (
          <label>Loading models... (only run once)</label>
        )}
        {progressItems.map(data => (
          <div key={data.file}>
            <TranslateProgress text={data.file} percentage={data.progress} />
          </div>
        ))}
      </div>
      
      {output && (
        <div>
          <div className='answerFinderSection'>
            <span>
              Answer: <strong>{output.answer}</strong>
            </span>
            <br/>
            <span>
              Score: <strong>{output.score.toFixed(2)}</strong>
            </span>
          </div>

          <div className='answerFinderSection'>
              {nearAnswerText}
            </div>
        </div>
      )}

      {context && question && output && (
        <div className='answerFinderSection'>
          <div className='answerFinderButtons'>
            <button className='btnBase'
              onClick={() => {
                combinedTextDownload(context, question, output);
              }}
            >
              Download context & answer {marks.downloadMark}
            </button>
            <button className='btnBase'
              onClick={() => {
                setShowUploadModal(true);
                createCombinedTextFile(context, question, output);
              }}
            >
              Upload context & answer {marks.uploadMark} 
            </button>
          </div>
        </div>
      )}

      {showUploadModal && (
        <div>
          <AnswerFinderFileUpload 
            combinedFile={combinedFile}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
      {/* <div>progress: {JSON.stringify(downloadData.progress)}</div> */}
      {/* <div>progressData: {JSON.stringify(downloadData)}</div> */}
    </div>
    </Fragment>
  )
}

export default AnswerFinder
