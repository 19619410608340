import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import VoiceFileControl from './FileControl/VoiceFileControl';
import { useStore } from '../../hook-store/store';

import './VoiceGenerator2.css';


function VoiceGenerator2Output(props) {
  const {
    audioBlobUrls,
    outputAudioEls,
  } = props;

  const [store, dispatch] = useStore();
  const { paramFileData } = store.uiStore;

  // const [isGenerating, setIsGenerating] = useState(false);




  let outputBody;

  const displayList = [];

  if (audioBlobUrls.length > 0) {
    const ttsClipLabels = document.getElementsByClassName('tts-clip-label');

    for (let i=0; i<audioBlobUrls.length; i++) {
      displayList.push({
        blobUrl: audioBlobUrls[i],
        text: ttsClipLabels[i].innerHTML,
        time: Number(outputAudioEls[i].id?.split('-')[1]),
      });
    }

    outputBody = (
      <ul>
        {[displayList[audioBlobUrls.length-1]].map((ele, index) => {
          // console.log(ele)
          return (
            <li key={ele.blobUrl}
              className='voiceGenerator2OutputCard'
            >
              <audio className='voiceGenerator2Audio'
                src={ele.blobUrl}
                controls 
              />
              <div>
                {ele.text.length > 100 && (
                  <span>{ele.text.substring(0, 100)}....</span>
                )}
                {ele.text.length <= 100 && (
                  <span>{ele.text}</span>
                )}
              </div>
              {ele.time > 0 && (
                <div>
                  {new Date(ele.time).toLocaleString()}
                </div>
              )}
            </li>
        )
      })}
      </ul>
    )
  }

  return (
    <Fragment>
      {outputBody}

      {audioBlobUrls.length > 0 && (
        <VoiceFileControl 
          textForOutput={displayList[displayList.length - 1].text}
        />
      )}
    </Fragment>
  )
}

export default VoiceGenerator2Output;
