// import { PlayIcon } from "@radix-ui/react-icons"
import { Fragment, useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import { useStore } from "../../hook-store/store"
import { 
  getObjectFile, 
  getOriginalFileNameFromKey,
  getObjectSignedUrl,
} from '../../utils/bucket/bucket-object-util';

import { marks } from "../../utils/marks";
import { 
  loginPageLink, 
  KURASTORAGE_BACKENDURL,
} from "../../App";

const LoadParamFile = (props) => {
  const {
    setInputFile,

    setSelectedFileUrl,
    setSelectedDisplayImage,
  } = props;


  // const { toast } = useToast()

  const [t] = useTranslation('translation');

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [store, dispatch] = useStore();
  const { 
    // showToggleNav, 
    // gLoading,
    isAuth,
   } = store.uiStore;

   const [showLoadFileModal, setShowLoadFileModal] = useState(false);
   const [loadMoadlContent, setLoadModalContent] = useState();
   const [isLoading, setIsLoading] = useState(false);

  //  useEffect(() => {

  //  }, [imageHeight, imageWidth]);

  const loginRequireContent = (
    <div>
      <div>
        {t('fileControl01' ,'Login is required for loading file')} 
        {' '}
        from Kura Storage.
      </div>
      <br/>
      <div>
        <a href={loginPageLink} >
          <button>
            {marks.signInMrak} {t('fileControl02' ,'Login')}
          </button>
        </a>
      </div>
    </div>
  );

  const differentUserContent = (
    <div>
      <div>
        {t('fileControl03' ,'Login user is different. To load file, please login using same email address after logout from menu.')}
      </div>
      <br/>

      <div>
        {t('fileControl04' ,'Current Login User Email')}
        {' '}
        : {localStorage.getItem('email')}
      </div>
    </div>
  );

   useEffect(() => {
      loadParamFileHandler();
   },[isAuth]);


   const loadParamFileHandler = async () => {
    try {
      setShowLoadFileModal(false);
      setLoadModalContent('');

      const currentUrl = new URL(window.location.href);
      const queryParams = currentUrl.searchParams;
      const key = queryParams.get('key');
      const mimeType = queryParams.get('mimeType');
      const kuraStorageUserId = queryParams.get('kuraStorageUserId');
      const pathname = window.location.pathname;
      
      let keyData = queryParams.get('keyData');

      if (keyData) {
        keyData = JSON.parse(keyData);
      }


      // console.log('loadParam', key, mimeType);
      if (kuraStorageUserId) {

        if (!isAuth && !localStorage.getItem('userId')) {
          // alert('login-is-required');
          setShowLoadFileModal(true);
          setLoadModalContent(loginRequireContent);
          
          return;
        }

        if (isAuth && kuraStorageUserId !== localStorage.getItem('userId')) {
          // alert('auth-user-is-different');
          setShowLoadFileModal(true);
          setLoadModalContent(differentUserContent);

          return;
        }

        setIsLoading(true);

        if (keyData) {
          const paramFiles = await getKeyDataFileHandler(keyData);
          
          // console.log(paramFiles);
        } 
        if (key && mimeType) {
          const paramFile = await getObjectFileHandler(
            key,
            mimeType,
            ''
          );
  
          console.log('param file', paramFile);
  
          if (paramFile) {
            dispatch('SET_PARAMFILEDATA', {
              key: key,
              mimeType: mimeType,
              // url: signedUrl,
              file: paramFile,
              openApp: queryParams.get('openApp'),
            });
          }
        }

        const openApp = queryParams.get('openApp');

        searchParams.delete('key');
        searchParams.delete('mimeType');
        searchParams.delete('kuraStorageUserId');
        searchParams.delete('openApp');
        searchParams.delete('keyData');
        setSearchParams(searchParams);

        if (openApp === 'voiceGenerator') {
          // dispatch('SET_GLOADING', false);
          setIsLoading(false);
          navigate('/voice-generator');
        }

        if (openApp === 'audioSlicer') {
          // dispatch('SET_GLOADING', false);
          setIsLoading(false);
          navigate('/audio-editor');
        }
        


        // dispatch('SET_GLOADING', false);
        setIsLoading(false);
        // window.history.push(window.location.pathname + "?" + currentUrlParams.toString());
      }
    
    } catch(err) {
      console.log(err);
      // dispatch('SET_GLOADING', false);
      setIsLoading(false);
    }
   }


   const getObjectFileHandler = async (
      key, 
      mimeType, 
      pathname,
    ) => {
      try {

        setIsLoading(true);

        const resData = await getObjectFile(
          KURASTORAGE_BACKENDURL,
          localStorage.getItem('token'),
          localStorage.getItem('userId'),
          key,
          mimeType,
        );

        if (resData) {
          console.log(resData);

          const originalName = getOriginalFileNameFromKey(key);

          const file = new File([resData], originalName, {type: mimeType});
          // console.log('loadparam file', file);
          if (file) {
            return file;
          }
        }

        setIsLoading(false);

      } catch(err) {
        console.log(err);
        setIsLoading(false);
        throw err;
      }
    };


    const getKeyDataFileHandler = async (keyData) => {
      try {
        setIsLoading(true);

        // console.log('keyData', keyData)
        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const openApp = queryParams.get('openApp');

        const keyFiles = keyData.files;
        const fileData = {
          files: [],
          openApp: openApp,
        };


        for (const keyFile of keyFiles) {
          const resData = await getObjectFile(
            KURASTORAGE_BACKENDURL,
            localStorage.getItem('token'),
            localStorage.getItem('userId'),
            keyFile.key,
            keyFile.mimeType,
          );
  
          if (resData) {
            console.log(resData);
  
            const originalName = getOriginalFileNameFromKey(keyFile.key);
            
            let fileMimeType = keyFile.mimeType;

            if (!fileMimeType) {
              fileMimeType = 'audio/wav';

              // const lowerKey = keyFile.key.toLowerCase();
              // const ext = lowerKey.split('.').pop();
              
              // if (ext === 'png') {
              //   fileMimeType = 'image/png'
              // }
            }
  
            const file = new File([resData], originalName, { type: fileMimeType });

            if (file) {
              fileData.files.push(file);
            }
          }

        }

        dispatch('SET_PARAMFILEDATALIST', fileData);
        
        //// open target app modal

        // if (openApp === 'imageToImageGeneration') {
        //   dispatch('SET_SHOWIMAGETOIMAGESETTINGS', true);
        // }


        // dispatch('SET_SHOWLOADFILEMODAL', false);

        setIsLoading(false);

        return fileData;
      } catch(err) {
        console.log(err);
        setIsLoading(false);
        // setLoadModalContent(loadErrorContent);
        throw err;
      }
    };




  return (
    <Fragment>
      {showLoadFileModal && (
        <Modal
          onClose={() => {
            setShowLoadFileModal(false);
          }}
        >
          <div>
            {loadMoadlContent}
          </div>
        </Modal>
      )}
      {isLoading && (
        <Loader />
      )}
    </Fragment>
  )
}

export default LoadParamFile;
