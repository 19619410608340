import { Fragment, useRef, useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import _ from "lodash";
// import { convert } from 'video-to-audio';

import Loader from '../../Loader/Loader.jsx';

import { useStore } from '../../../hook-store/store.js';
// import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';

function AudioAmpChangerSelectFile(props) {
  const {
    audioFiles,
    setAudioFiles,
    getAudioFileData,
  } = props;

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    paramFileDataList,
  } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (audioFiles.length === 0 && paramFileDataList && paramFileDataList.files?.length > 0 &&
            paramFileDataList.openApp === 'audioAmpChanger'
      ) {
        selectCombinerFilesHandler(paramFileDataList.files);
        dispatch('SET_PARAMFILEDATALIST', null);
      }
  
    },[paramFileDataList]);

  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const selectCombinerFilesHandler = async (inputFiles) => {
    try { 
      // if (audioFiles.length >= 1) {
      //   return;
      // }

      setIsLoading(true);

      if (inputFiles.length > 0) {
        const files = [];

        for (const file of inputFiles) {
          const audioFileData = await getAudioFileData(file);
          
          files.push(audioFileData);
        }

        let newFiles

        if (audioFiles.length > 0) {
          newFiles = audioFiles.concat(files).slice(1,2);
        } else {
          newFiles = audioFiles.concat(files).slice(0,1);
        }
  
        setAudioFiles(newFiles);

        setIsLoading(false);
      }
    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  }

  let audioAmpChangerSelctFileBody

  audioAmpChangerSelctFileBody = (
    <div>
      <input 
        type='file'
        accept='audio/*'
        ref={hiddenFileSelect}
        style={{display:"none"}}
        onChange={(event) => {
          selectCombinerFilesHandler(event.target.files);
        }}
        // multiple
      />
      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        disabled={audioFiles.length >= 2 || isLoading} 
        onClick={handleInputClick}
      >
        {/* {marks.fileTextOMark}{' '} */}
        Import audio file
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        {audioAmpChangerSelctFileBody}
      </div>
      {isLoading && (
        <Loader />
      )}
    </Fragment>
  )
}

export default AudioAmpChangerSelectFile;