import { useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Backdrop from '../../Backdrop/Backdrop';
import Loader from '../../Loader/Loader';
import Modal from '../../Modal/Modal';
import UploadedFileUseContent from '../../UploadedFileUseContent/UploadedFileUseContent';
import VoiceGenerator2Socket from '../VoiceGenerator2Socket';
import VoiceGenerator2NoEnOutput from './VoiceGenerator2NoEnOutput';

import { useStore } from '../../../hook-store/store';
import { 
  generateTextAudio, 
  getTextToSpeechModels,
  getTextToSpeechApi,
} from '../../../utils/voice-generator2-utils/voice-generator2-utils'

import { TEXTAUDIOTOOLSNODE_URL } from '../../../App';

import { marks } from '../../../utils/marks';
import '../VoiceGenerator2.css';

// const modelList = [
//   {language: 'en', repoId: 'csukuangfj/vits-piper-en_US-glados|1 speaker'},
//   {language: 'tha', repoId: 'csukuangfj/vits-mms-tha'},
//   {language: 'nan', repoId: 'csukuangfj/vits-mms-nan'},
// ];
  // Thai "csukuangfj/vits-mms-tha", ฉันรักคุณ
  // Min-nan (闽南话) "csukuangfj/vits-mms-nan",  ài piaǸ chiah ē iaN̂
  

function VoiceGenerator2NoEn() {

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    textToSpeechApiExist,
  } = store.mlVoiceStore;
  const { paramFileData } = store.uiStore;

  const [isModelLoading, setIsModelLoading] = useState(true);
  const [generatedAudioFiles, setGeneratedAudioFiles] = useState([]);
  const [textInput, setTextInput] = useState('');
  const [ttsGenLng, setTtsGenLng] = useState('');
  const [ttsGenRepoId, setTtsGenRepoId] = useState("");
  const [speakerId, setSpeakerId] = useState('0');
  const [genSpeed, setGenSpeed] = useState(1);
  const [ttsGenModels, setTtsGenModels] = useState([]);
  const [ttsGenMaxText, setTtsGenMaxText] = useState(0);
  const [showModalUseUploadedFile, setShowModalUseUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTtsModelsHandler();
  },[]);


  useEffect(() => {

    getTtsApiHandler();

    const getApiInterval = setInterval(() => {
        getTtsApiHandler();
      }, 1000*10);

    return () => {
      clearInterval(getApiInterval);
    }

  },[]);


  useEffect(() => {
    if (paramFileData && paramFileData.openApp === 'voiceGeneratorNoen' 
          && !isModelLoading
    ) {
      setParamFileHandler();
    }

  }, [paramFileData, isModelLoading]);


  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const textInputHandler = (event) => {
    setTextInput(event.target.value);
    // const textArea = document.getElementById('tts-text');
    // textArea.value = event.target.value;
  }

  // Thai "csukuangfj/vits-mms-tha", ฉันรักคุณ
  // Min-nan (闽南话) "csukuangfj/vits-mms-nan",  ài piaǸ chiah ē iaN̂
  

  const setParamFileHandler = async () => {
    try {
      const files = [paramFileData.file];

      const fileUrl = URL.createObjectURL(files[0]);
      // const mimeType = files[0].type;

      const response = await fetch(fileUrl);

      const resData = await response.text();

      if (resData) {
        setTextInput(resData);
  
        dispatch('SET_PARAMFILEDATA', null);
      }


    } catch(err) {
      console.log(err);
    }
  };


  const generateAudioHandler = async () => {
    // const generateBtn = document.getElementById('tts-generateBtn');
    // generateBtn.click();
    try {

      setIsLoading(true);
      dispatch('CLEAR_NOTIFICATION');

      let sId = speakerId;

      if (!speakerId) {
        sId = '0';
      }

      // throw new Error('error-error')

      const audioFileData = await generateTextAudio(
        TEXTAUDIOTOOLSNODE_URL,
        localStorage.getItem('token'),
        ttsGenLng,
        ttsGenRepoId,
        textInput,
        sId,
        genSpeed,
      );

      if (audioFileData) {
        const modelDisplayName = createModelDisplayName(
          ttsGenModels, 
          ttsGenLng,
          ttsGenRepoId,
        );
        
        const addedList = [
          {
            ...audioFileData,
            modelDisplayName: modelDisplayName,
          }
        ].concat(generatedAudioFiles)
        setGeneratedAudioFiles(addedList.slice(0,3));

        dispatch('SHOW_NOTIFICATION', {
          status: '',
          title: 'audio generated',
          message: '',
        });
  
        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        },1000 * 5);
      }

      setIsLoading(false);

    } catch(err) {
      setIsLoading(false);
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'audio generation failed',
        message: '',
      });
      // throw err;
    }
  };

  const getTtsModelsHandler = async () => {
    try {

      setIsModelLoading(true);

      const resData = await getTextToSpeechModels(
        TEXTAUDIOTOOLSNODE_URL,
        localStorage.getItem('token'),
      );

      const ttsModelList = [];

      if (resData.data) {
        for (const data in resData.data) {
          // console.log(model);
          ttsModelList.push({
            code: data,
            name: resData.data[data].name,
            models: resData.data[data].models,
          })
        }

        console.log(ttsModelList);
        setTtsGenModels(ttsModelList);
        setTtsGenLng(ttsModelList[0].code);
        // setTtsGenRepoId(ttsModelList[0].models[0])
        setTtsGenMaxText(resData.ttsMaxTextLength);
      }

      setIsModelLoading(false);

    } catch(err) {
      setIsModelLoading(false);
      console.log(err);
      // throw err;
    }

  }

  const getTtsApiHandler = async () => {
    try {
      const resData = await getTextToSpeechApi(
        TEXTAUDIOTOOLSNODE_URL,
        localStorage.getItem('token'),
      );

      if (resData) {
        console.log(resData);
        dispatch('SET_TEXTTOSPEECHAPIEXIST', true);
      }

    } catch(err) {
      console.log(err);
      dispatch('SET_TEXTTOSPEECHAPIEXIST', false);
      // throw err;
    }

  }

  const changeSpeakerIdHandler = (event) => {
    // const speakerIdInput = document.getElementById('tts-speakerId');
    // speakerIdInput.value = event.target.value;

    console.log(event.target.value, numberOfSpeakerId)
    if (Number(event.target.value) >= 0 && Number(event.target.value) < numberOfSpeakerId) {
      setSpeakerId(event.target.value);
    }

    if (numberOfSpeakerId === 1) {
      setSpeakerId('0');
    }
  }

  const changeSpeedHandler = (event) => {
    // const speedInput = document.getElementById('tts-speed');
    // const speedValue = document.getElementById('tts-speedValue');

    // speedInput.value = event.target.value;
    // speedValue.innerHTML = event.target.value;
    // setSpeed(event.target.value);

    setGenSpeed(Number(event.target.value));
  }

  const selectRepoIdHandler = (event, displayName) => {
    setTtsGenRepoId(event.target.value);

    if (getSpeakerNumFromRepoId(event.target.value) > 1) {
      setSpeakerId('');
    } else {
      setSpeakerId('0');
    }
  };

  const selectTtsGenLngHandler = (event) => {
    setTtsGenLng(event.target.value);
    setTtsGenRepoId('');
    setSpeakerId('0');
  }
  
  const getSpeakerNumFromRepoId = (repoId) => {
    const spNum = Number(repoId.split('|')[1]);

    if (spNum > 1) {
      return spNum;
    } else {
      return 1;
    }
    // return Number(repoId.split('|')[1]);
  }

  const createModelDisplayName = (ttsGenModels, ttsGenLng, ttsGenRepoId) => {
    let modelDisplayName = '';

    if (ttsGenModels.length > 0 && ttsGenLng) {
      const selectedModelData = ttsGenModels.find(model => {
        return model.code === ttsGenLng;
      })
  
      if (selectedModelData) {
      
        if (ttsGenRepoId) {
          const repoIndex = selectedModelData.models.indexOf(ttsGenRepoId);
          const modelDesc = ttsGenRepoId.split('/')[1].split('|')[0];

          modelDisplayName = `${selectedModelData.name} - ${repoIndex + 1} (${modelDesc})`;
        }
      }
    }

    return modelDisplayName;

  };


  const loadTextFileHandler = async (event) => {
    try {
      
      const file = event.target.files[0]
      
      if (file.type !== 'text/plain') {
        return;
      }
      
      setIsLoading(true);

      const fileUrl = URL.createObjectURL(file);

      const result = await fetch(fileUrl);

      const resText = await result.text();

      if (resText) {
        setTextInput(resText);
      }
      
      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };


  const displayList = [];


  let selectedLngModels = [];
  let genButtonDisabled = false;
  let genButtonText = `${t('voiceGenerator07', 'Generate audio')}`;
  let numberOfSpeakerId = 1;

  if (ttsGenModels.length > 0 && ttsGenLng) {
    const selectedModelData = ttsGenModels.find(model => {
      return model.code === ttsGenLng;
    })

    if (selectedModelData) {
      selectedLngModels = [''].concat(selectedModelData.models);
    }

  }

  if (isModelLoading || isLoading || !ttsGenLng || !ttsGenRepoId || !textInput) {
    genButtonDisabled = true;
  }

  if (!textToSpeechApiExist) {
    genButtonDisabled = true;
    genButtonText = `${t('voiceGenerator08', 'Busy...')}`
  }

  if (textInput && textInput.length > ttsGenMaxText) {
    genButtonDisabled = true;
  }

  if (ttsGenRepoId && getSpeakerNumFromRepoId(ttsGenRepoId) > 1) {
    numberOfSpeakerId = getSpeakerNumFromRepoId(ttsGenRepoId);
  }

  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
          style={{width:"310px", height:"70px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
      style={{width:"460px", height:"95px", border:"none"}}  
      src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
          style={{width:"610px", height:"135px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
      />
    );
  }

  return (
    <Fragment>
      {/* {!textToSpeechApiExist && (
        <div>not-api-exist</div>
      )} */}
      <div className='voiceGenerator2'>
        <div 
          style={{
                  display: 'flex',
                  justifyContent: "center",
                  paddingTop: "1rem",
                  paddingBottom: "2rem"
              }}
          >
            {bannerElement}
        </div>

        <div className='voiceGenerator2Section'>
          <div className='voiceGenerator2Title'>
            Voice Generator
          </div>
          <div className='voiceGenerator2TitleSub'>
            (<Link to="/voice-generator">
              {t('voiceGenerator01', 'go to Voice Generator for English page')}
            </Link>)
          </div>
        </div>

        <div className='voiceGenerator2Section'>
          <div>
            {t('voiceGenerator02', 'Language')}
          </div>
          <select className='voiceGenerator2Select'
              onChange={selectTtsGenLngHandler} 
              // defaultValue={defaultLanguage}
            >
              {ttsGenModels.map((model) => {
                return (
                  <option key={model.code} value={model.code}>
                    {model.name}
                  </option>
                )
              })}
            </select>
            
            {/* <div>
              tts-gen-lng: {ttsGenLng}
            </div> */}
        </div>
        

        <div className='voiceGenerator2Section'>
          <div>
            {t('voiceGenerator03', 'Model')}
          </div>
          <select className='voiceGenerator2Select'
              onChange={selectRepoIdHandler} 
              // defaultValue={defaultLanguage}
            >
              {selectedLngModels.map((model, index) => {
      
                let dispSpeakerNum;
                let dispModelName;

                if (Number(model.split('|')[1]) > 1) {
                  dispSpeakerNum = <span>(speaker {model.split('|')[1]})</span>;
                }

                const ttsModelData = ttsGenModels.find(model => {
                  return model.code === ttsGenLng;
                });
                if (ttsModelData && index > 0) {
                  dispModelName = createModelDisplayName(
                    ttsGenModels,
                    ttsGenLng,
                    model,
                  )
                  // dispModelName = `${ttsModelData.name} - ${index}`;
                }

                return (
                  <option key={model} value={model}>
                    {dispModelName} {dispSpeakerNum}
                  </option>
                )
              })}
            </select>
            {/* <div>model-disp-name: {modelDisplayName}</div> */}
            {/* <div>
              selected-model: {ttsGenRepoId}
            </div> */}
        </div>

        
        {isModelLoading && (
          <div className='voiceGenerator2Section'>
            model loading... 
            <br/>
            <Loader />
          </div>
        )}

        {/* <div className='voiceGenerator2Section'>
          This page supports English only
        </div> */}

        {numberOfSpeakerId > 1 && (
          <div className='voiceGenerator2Section'>
            <label htmlFor="speakerId">
              {t('voiceGenerator09', 'Speaker')} ID 
            </label>
            <div className='voiceGenerator2SettingRow'>
              <div>
                <input 
                  className='voiceGenerator2SpeakerInput'
                  style={{padding: "0.25rem"}}
                  type="text" name="speakerId" 
                  // value={speakerId}
                  // value="0" 
                  placeholder={`0 - ${numberOfSpeakerId - 1}`}
                  onChange={changeSpeakerIdHandler}
                  // defaultValue={speakerIdInputValue}
                  defaultValue={''}
                />
                <span>
                  {' '}{speakerId}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className='voiceGenerator2Section'>
            <label htmlFor="speed">
              {t('voiceGenerator04', 'Speed')}
            </label>
            <div className='voiceGenerator2SettingSpeed'>
              <input className='voiceGenerator2SettingSpeedInput'
                type="range" name="speed" 
                min="0.4" max="3.5" step="0.1" 
                // value={speed ? speed : speedInputValue} 
                value={genSpeed ? genSpeed : 1} 
                onChange={changeSpeedHandler}
                // defaultValue={speakerIdInputValue}
                defaultValue={genSpeed}
              />
              <span>{genSpeed}</span>
              {/* {speed && (
                <span>{speed}</span>
              )}
              {!speed && (
                <span>{speedInputValue}</span>
              )} */}
          </div>
        </div>

        <div className='voiceGenerator2Section'>
          <div>
            <div className='voiceGenerator2TextInputLabels'>
              <label>
                {t('voiceGenerator05', 'text input')}
              </label>
              <input 
                type='file'
                accept='text/plain'
                ref={hiddenFileSelect}
                style={{display:"none"}}
                onChange={loadTextFileHandler}
              />
              <button className="btnBase"
                style={{border:"0.5px solid gray", borderRadius: "4px"}}
                disabled={isLoading} 
                onClick={handleInputClick}
              >
                {marks.fileTextOMark}{' '}
                {t('voiceGenerator14', 'import text file')}
              </button>
              <button className="btnBase"
                style={{border:"0.5px solid gray", borderRadius: "4px"}}
                disabled={isLoading} 
                onClick={() => { setShowModalUseUploaded(true) }}
              >
                {marks.downloadMark} {t('voiceGenerator15', 'use uploaded file')}
              </button>
            </div>
            {showModalUseUploadedFile && (
              <div>
                <Backdrop 
                  zIndex={'95'}
                  onCancel={() => { 
                    setShowModalUseUploaded(false);
                  }}
                />
                <Modal
                  onClose={() => {
                    setShowModalUseUploaded(false);
                  }}
                >
                  <UploadedFileUseContent
                    setShowModalUseUploaded={setShowModalUseUploaded}
                  />
                </Modal>
              </div>
            )}
          </div>
          <textarea 
            className='voiceGenerator2TextInput'
            rows="3" 
            placeholder="Please enter your text here and click the Generate button"
            onChange={textInputHandler}
            defaultValue={''}
            value={textInput}
          ></textarea>
          <div
            style={textInput.length > ttsGenMaxText ? { color: "red"}: null}
          >
            {t('voiceGenerator06', 'length')} {textInput.length} / {ttsGenMaxText}
          </div>
        </div>

        
        <div className='voiceGenerator2Section'>
          <button className='btnBase'
            style={{ 
              padding: "0.75rem", 
              fontSize: "1.25rem", 
              fontWeight: "bold",
              color: genButtonDisabled ? 'gray' : 'inherit',
            }}
            disabled={genButtonDisabled}
            onClick={generateAudioHandler}
          >
            {genButtonText}
          </button>
          {isLoading && ( <Loader />)}
        </div>

        {/* {isGenerating && (
          <Loader />
        )} */}

        {/* <span id='tts-audio-output'></span> */}
        <section></section>

        <div className='voiceGenerator2Section'>
          <VoiceGenerator2NoEnOutput 
            generatedAudioFiles={generatedAudioFiles}
            textInput={textInput}
          />
        </div>
      </div>

      <VoiceGenerator2Socket />
    </Fragment>
  )
}

export default VoiceGenerator2NoEn;
