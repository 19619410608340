import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../hook-store/store";

import { kuraStorageLink } from '../../App';

import './AboutPage.css';
import './AboutPageAudioEditor.css';

const AboutPageAudioEditor = (props) => {

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  return (
    <Fragment>
        <p>
          In <strong>Audio Editor,</strong> 
          {' '}
          user can edit audio files. 
          <div className="paraSection">
            <strong>
              Audio Slicer: 
            </strong>
            {' '}
            It is possible to trim or slice audio file. 
            {' '}
            Audio Slicer{' '}
            has audio recorder. Recorded audio can be sliced after recording.
          </div>
          <div className="paraSection">
          <strong>
              Audio Combiner: 
            </strong>{' '}
            User can combine audio files.
          </div>
          <div className="paraSection">
          <strong>
              Audio Amplitude Changer: 
            </strong>{' '}
            User can change amplitude of audio file.
          </div>
          <div className="paraSection">
            <strong>
              Compress Audio File: 
            </strong>{' '}
            User can change file format to MP3 and compress audio file.
          </div>
          
          <div>
            {t('aboutPage.28', 'User can download the file or upload the file')} 
            {' '}to{' '}
            <a href={`${kuraStorageLink}/about`}
              target="_blank" rel="noopener noreferrer"
            >
              <strong>Kura Storage</strong>
            </a>. 
          </div>
        </p>
   

 

   
    </Fragment>
  );
};
export default AboutPageAudioEditor;
