import { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import _ from "lodash";

import AudioConvertToMP3AudioList from './AudioConvertToMP3AudioList.jsx';
import AudioConvertToMP3Convert from './AudioConvertToMP3Convert.jsx';
import AudioConvertToMP3SelectFile from './AudioConvertToMp3SelectFiles.jsx';

import { useStore } from '../../../hook-store/store.js';
import { decodeAudioBuffer } from '../AudioSlicer/audio-helper.ts';

import "./AudioConvertToMP3.css";


function AudioConvertToMP3(props) {
  const {
    // handleFileChange,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const [audioFiles, setAudioFiles] = useState([]);
  const [currentTimes, setCurrentTimes] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [AudioEls, setAudioEls] = useState();
  const [combinedFile, setCombinedFile] = useState();
  const [widthScaleFactor, setWidthScaleFactor] = useState(1);


  const playSound = (event, id) => {
    // button text
    // get the audio element with the same id as the button text
    document.getElementById(id).play();
  }

  const pauseSound = (event, id) => {
    // button text
    // get the audio element with the same id as the button text
    document.getElementById(id).pause();
  }

  const pauseElementSounds = (elementIds) => {
    // button text
    // get the audio element with the same id as the button text
    for (const elementId of elementIds) {

      const element = document.getElementById(elementId);

      if (element) {
        element.pause();
      }
    }
  };

  const restartSound = (event, id) => {
    // button text
    // get the audio element with the same id as the button text
    const el = document.getElementById(id);
    el.currentTime = 0;
    el.play();
  };

  const getAudioFileData = async (file) => {
    try {
      const audioBuffer = await decodeAudioBuffer(file);
        
      let maxOfMax = 0;

      for (let i = 0; i < audioBuffer.numberOfChannels; i += 1) {
        const audioData = audioBuffer.getChannelData(i)
        
        const max = _.max(audioData);
        // console.log('max i', max, i)

        if (max > maxOfMax) {
          maxOfMax = max;
        }
      }
      // console.log('maxOfMax', maxOfMax);
      return {
        id: Math.random(),
        file: file,
        audioBuffer: audioBuffer,
        bufferMax: maxOfMax,
      };

    } catch(err) {
      console.log(err);
      throw err;
    }
  };



  let audioAmpChangerBody

  audioAmpChangerBody = (
    <div>
        <div className='audioConvertToMP3Section'>
          <AudioConvertToMP3SelectFile
            audioFiles={audioFiles}
            setAudioFiles={setAudioFiles}
            getAudioFileData={getAudioFileData}
          />
        </div>
        <div className='audioConvertToMP3Section'>
          <AudioConvertToMP3AudioList 
            audioFiles={audioFiles}
            setAudioFiles={setAudioFiles}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            playSound={playSound}
            pauseSound={pauseSound}
            restartSound={restartSound}
            pauseElementSounds={pauseElementSounds}
            widthScaleFactor={widthScaleFactor}
            setWidthScaleFactor={setWidthScaleFactor}
          />
        </div>

        <div className='audioConvertToMP3Section'>
          <AudioConvertToMP3Convert 
            audioFiles={audioFiles}
            combinedFile={combinedFile}
            setCombinedFile={setCombinedFile}
            playSound={playSound}
            pauseSound={pauseSound}
            restartSound={restartSound}
            getAudioFileData={getAudioFileData}
            widthScaleFactor={widthScaleFactor}
            setWidthScaleFactor={setWidthScaleFactor}
          />
        </div>

    </div>
  )




  return (
    <Fragment>
      <div>
        {audioAmpChangerBody}
      </div>
    </Fragment>
  )
}

export default AudioConvertToMP3;