import { Fragment, useRef, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

// import { convert } from 'video-to-audio';
import Backdrop from '../../Backdrop/Backdrop.jsx';
import AudioRecorder from '../AudioRecorder.tsx';
import Modal from '../../Modal/Modal.jsx';
import ModalConfirm from '../../Modal/ModalConfirm.jsx';
import UploadedFileUseContent from '../../UploadedFileUseContent/UploadedFileUseContent.jsx';

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import './AudioSlicer.css';

function AudioSlicerSelectFile(props) {
  const {
    handleFileChange,
    file,
  } = props;

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    paramFileDataList
  } = store.uiStore;

  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showModalUseUploadedFile, setShowModalUseUploaded] = useState(false);

  useEffect(() => {
    if (!file && paramFileDataList && paramFileDataList.files?.length > 0 &&
          paramFileDataList.openApp === 'audioSlicer'
    ) {
      handleFileChange(paramFileDataList.files[0]);
      dispatch('SET_PARAMFILEDATALIST', null);
    }

  },[paramFileDataList]);

  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const selectSlicerFileHandler = (event) => {
    if (event.target.files[0]) {
      handleFileChange(event.target.files[0])
    }
  }

  const onRecordingComplete = (blob) => {
    const recordedFile = new File([blob], `recordAudio-${Date.now()}.wav`, {type: 'audio/wav'});
    
    handleFileChange(recordedFile)
    setShowRecordModal(false);
  };

  let audioSlicerSelectFileBody

  audioSlicerSelectFileBody = (
    <div className='audioSlicerSelectButtons'>
      <input 
        type='file'
        accept='audio/*'
        ref={hiddenFileSelect}
        style={{display:"none"}}
        onChange={selectSlicerFileHandler}
      />
      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        // disabled={isLoading} 
        onClick={handleInputClick}
      >
        {/* {marks.fileTextOMark}{' '} */}
        {marks.fileMark} Import audio file
      </button>

      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        // disabled={isLoading} 
        onClick={() => { 
          setShowModalUseUploaded(true); 
        }}
      >
        {/* {marks.fileTextOMark}{' '} */}
        {marks.downloadMark} Uploaded file 
      </button>

      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        // disabled={isLoading} 
        onClick={() => { setShowRecordModal(true) }}
      >
        {/* {marks.fileTextOMark}{' '} */}
        {marks.microphoneMark} Record Audio
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        {audioSlicerSelectFileBody}
      </div>
      {showRecordModal && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { setShowRecordModal(false); }}
          />
          <ModalConfirm
              onClose={() => { setShowRecordModal(false); }}
              hideButtons={true}
          >
            <AudioRecorder
                onRecordingComplete={onRecordingComplete}
                onCancel={() => { setShowRecordModal(false); }}
            />
          </ModalConfirm>
        </div>
      )}
      {showModalUseUploadedFile && (
          <div>
            <Backdrop 
              zIndex={'95'}
              onCancel={() => { 
                setShowModalUseUploaded(false);
              }}
            />
            <Modal
              onClose={() => {
                setShowModalUseUploaded(false);
              }}
            >
              <UploadedFileUseContent
                setShowModalUseUploaded={setShowModalUseUploaded}
              />
            </Modal>
          </div>
        )}
    </Fragment>
  )
}

export default AudioSlicerSelectFile;