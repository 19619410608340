import { Fragment, useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next";

// import { convert } from 'video-to-audio';

import { useStore } from '../../../hook-store/store.js';

import './AudioSlicer.css';

function AudioSlicerSelectFile(props) {
  const {
    handleFileChange,
    file,
  } = props;

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    paramFileDataList
  } = store.uiStore;

  useEffect(() => {
    if (!file && paramFileDataList && paramFileDataList.files?.length > 0 &&
          paramFileDataList.openApp === 'audioSlicer'
    ) {
      handleFileChange(paramFileDataList.files[0]);
      dispatch('SET_PARAMFILEDATALIST', null);
    }

  },[paramFileDataList]);

  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  };

  const selectSlicerFileHandler = (event) => {
    if (event.target.files[0]) {
      handleFileChange(event.target.files[0])
    }
  }

  let audioSlicerSelectFileBody

  audioSlicerSelectFileBody = (
    <div>
      <input 
        type='file'
        accept='audio/*'
        ref={hiddenFileSelect}
        style={{display:"none"}}
        onChange={selectSlicerFileHandler}
      />
      <button className="btnBase"
        style={{border:"0.5px solid gray", borderRadius: "4px"}}
        // disabled={isLoading} 
        onClick={handleInputClick}
      >
        {/* {marks.fileTextOMark}{' '} */}
        Import audio file
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        {audioSlicerSelectFileBody}
      </div>
    </Fragment>
  )
}

export default AudioSlicerSelectFile;