import { Fragment, useEffect, useRef, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";
import download from 'downloadjs';

import Backdrop from '../Backdrop/Backdrop';
import Loader from '../Loader/Loader';
import VideoToAudioFileUpload from './FileUpload/VideoToAudioFileUpload';
// import { convert } from 'video-to-audio';

import { useStore } from '../../hook-store/store';
import { convert } from '../../utils/video-to-audio-utils/video-to-audio-utils';
import { marks } from '../../utils/marks';

import './VideoToAudio.css';



function VideoToAudio() {

  const [t] = useTranslation("translation");

  const hiddenFileSelect = useRef(null);

  const [store, dispatch] = useStore();
  const { paramFileData } = store.uiStore;
  // const { mlSpeechText } = store.mlSpeechStore;

  // console.log('store-answer-finder', store)
  

  const [convertedAudioObj, setConvertedAudioObj] = useState();
  const [convertedAudioFile, setConvertedAudioFile] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [displayAudio, setDisplayAudio] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);
  
  // useEffect(() => {
  //   if (paramFileData && paramFileData.openApp === 'answerFinder') {
  //     setParamFileHandler();
  //   }

  // }, [paramFileData]);

  const handleInputClick = (event) => {
    hiddenFileSelect.current.click()
  }

  const createAudioFileFromAudioData = async (convertedAudioDataObj) => {
    try {
      const result = await fetch(convertedAudioDataObj.data);

      const resBlob = await result.blob();
      // console.log(result, resBlob);

      const format = convertedAudioDataObj.format;
      const fileName = `converted-audio-${Date.now()}.${format}`;
      const fileType = `audio/${format}`;
      const file = new File([resBlob], fileName, {type: fileType});

      setConvertedAudioFile(file);
      console.log(file);

    } catch(err) {
      console.log(err);
      throw err;
    }
  }

  async function convertToAudio(event) {
    try {
      dispatch('CLEAR_NOTIFICATION');
      setIsLoading(true);

      let sourceVideoFile = event.target.files[0];

      if (sourceVideoFile.type?.split('/')[0] !== 'video') {
        throw new Error('error occured');
      }

      // let sourceVideoFile = input.files[0];
      // let targetAudioFormat = 'mp3'
      let targetAudioFormat = 'wav'

      // if (sourceVideoFile.type === 'video/mp4') {
      //   targetAudioFormat = 'mp3'
      // }
      // console.log(sourceVideoFile, targetAudioFormat);

      let convertedAudioDataObj = await convert(sourceVideoFile, targetAudioFormat);
      console.log('converted', convertedAudioDataObj)
      setConvertedAudioObj(convertedAudioDataObj);

      createAudioFileFromAudioData(convertedAudioDataObj);

      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'audio file created',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 5);

    } catch(err) {
      console.log(err);
      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'audio file creation failed',
        message: '',
      });
    }
  }

  const convertedAudioDownload = (file) => {
    download(file, file.name, file.type);
  };

  const createDisplayAudio = () => {
    setDisplayAudio(
      <div>
        <div>
          {t('videoToAudio02', 'Converted audio')}
        </div>
        <div className='videoToAudioSection'>
          <audio className='videoToAudioAudio'
            src={URL.createObjectURL(convertedAudioFile)}
            controls
          />
        </div>
        <div className='videoToAudioSection'>
          <div className='videoToAudioButtons'>
            <button className='btnBase'
              disabled={isLoading}
              onClick={() => { 
                convertedAudioDownload(convertedAudioFile); 
              }}
            >
              {marks.downloadMark} {t('videoToAudio03', 'Download audio file')}
            </button>
            <button className='btnBase'
              disabled={isLoading}
              onClick={() => { setShowUploadModal(true) }}
            >
              {marks.uploadMark} {t('videoToAudio04', 'Upload audio file')}
            </button>
          </div>
        </div>
      </div>
    );
  }


  const expensiveValue = useMemo(() => {
    // console.log('Computing expensive value...');
    if (convertedAudioFile) {
      createDisplayAudio();
    }
  }, [convertedAudioFile]);



  // const setParamFileHandler = async () => {
  //   try {
  //     const files = [paramFileData.file];

  //     const fileUrl = URL.createObjectURL(files[0]);
  //     // const mimeType = files[0].type;

  //     const response = await fetch(fileUrl);

  //     const resData = await response.text();

  //     if (resData) {
  //       // setContext(resData);
  
  //       dispatch('SET_PARAMFILEDATA', null);
  //     }


  //   } catch(err) {
  //     console.log(err);
  //   }
  // }


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583665876"
          style={{width:"310px", height:"70px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583665876"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583679744"
      style={{width:"460px", height:"95px", border:"none"}}  
      src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583679744"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-text-audio-tools.spaceeight.net-1725583699146"
          style={{width:"610px", height:"135px", border:"none"}}  
          src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-text-audio-tools.spaceeight.net-1725583699146"
      />
    );
  }

  return (
    <Fragment>
      <div className='videoToAudio'>
        <div 
          style={{
              display: 'flex',
              justifyContent: "center",
              paddingTop: "1rem",
              paddingBottom: "2rem"
          }}
          >
            {bannerElement}
        </div>

      <div className='videoToAudioSection'>
        <div className='videoToAudioTitle'>
          Video to Audio
        </div>
      </div>

      <div className='videoToAudioSection'>
        <input 
          type='file' 
          // accept=".mp4, .avi, .mov" 
          // accept="video/mp4, video/x-msvideo, video/webm, .mov"
          accept="video/*"
          ref={hiddenFileSelect}
          style={{display:"none"}}
          onChange={(event) => {convertToAudio(event); }} 
        />
        <button className="btnBase"
          // style={ipaImages.length === 0 ? {display: "block"} : {display: "none"}} 
          disabled={isLoading} 
          onClick={handleInputClick}
        >
          {marks.fileVideoOMark}{' '}
          {t('videoToAudio01', 'Select video file')}  (mp4, avi, mov)
        </button>
      </div>
      {isLoading && (
        <div>
          <Backdrop 
          zIndex={'95'}
          onCancel={() => { 
            setShowUploadModal(false);
          }}
          />
          <Loader />
          </div>
      )}

      {/* {convertedAudioObj && (
        <div>
          <audio 
            src={convertedAudioObj.data}
            controls
          />
        </div>
      )} */}

      <div>
        {displayAudio}
      </div>

      {showUploadModal && (
        <div>
          <VideoToAudioFileUpload 
            uploadFiles={[convertedAudioFile]}
            setShowUploadModal={setShowUploadModal}
          />
        </div>
      )}
 
    </div>
    </Fragment>
  )
}

export default VideoToAudio;