import { Fragment, useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next";

// import { convert } from 'video-to-audio';

import { useStore } from '../../../hook-store/store.js';

import { marks } from '../../../utils/marks.jsx';
import './AudioSlicer.css';

function AudioSlicerControl(props) {
  const {
    setState,
    startTime,
    currentTime,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();


  const handlePlayClick = () => {
    setState({
      currentTime: currentTime,
      paused: false,
    });

    document.getElementById('audio-slicer-input-audio')?.play();
  };
  
  const handlePauseClick = () => {
    document.getElementById('audio-slicer-input-audio')?.pause();

    setState({
      paused: true,
    });
  };

  const handleRestartClick = () => {
    setState({
      currentTime: startTime,
      paused: false,
    });

    document.getElementById('audio-slicer-input-audio')?.play();
  }


  let audioSlicerControlBody

  audioSlicerControlBody = (
    <div>
      <button
        type="button"
        className="ctrl-item"
        title="Play"
        onClick={handlePlayClick}
      >
        {marks.playMark}
      </button>
      <button
        type="button"
        className="ctrl-item"
        title="Pause"
        onClick={handlePauseClick}
      >
        {marks.pauseMark}
      </button>
      <button
        type="button"
        className="ctrl-item"
        title="Replay"
        onClick={handleRestartClick}
      >
        {marks.shareMark}
      </button>
    </div>
  )

  return (
    <Fragment>
      <div>
        {audioSlicerControlBody}
      </div>
    </Fragment>
  )
}

export default AudioSlicerControl;