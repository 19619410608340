import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SHOW_NOTIFICATION: (state, payload) => {
      const notifyContent = {
        status: payload.status,
        title: payload.title,
        message: payload.message,
      };
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          notification: notifyContent, 
        }
      };
    },
    CLEAR_NOTIFICATION: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          notification: null, 
        }
      };
    },
    SET_GLOADING: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          gLoading: payload, 
        }
      };
    },
    SET_MLISBUSY: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          mlIsBusy: payload, 
        }
      };
    },
    SET_SRVLOADING: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          srvLoading: payload, 
        }
      };
    },
    SET_METALOADING: (state, payload) => {
      return { 
        // ...state,
        uiStore : {
          ...state.uiStore,
          metaLoading: payload, 
        }
      };
    },
    
    SET_WINDOW_VALUES: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          windowValues: payload,
        }
      }
    },
    SET_BOWSERDATA: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          bowserData: payload,
        }
      }
    },
    SET_SHOWTOGGLENAV: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          showToggleNav: payload,
        }
      }
    },
    SET_ISAUTH: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAuth: payload,
        }
      }
    },
    SET_ISADMIN: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAdmin: payload,
        }
      }
    },

    SET_ISALLAPIS: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          isAllApis: payload,
        }
      }
    },
    SET_DEFERREDPROMPT: (state, payload) => {
      return {
        // ...state,
        uiStore : {
          ...state.uiStore,
          deferredPrompt: payload,
        }
      }
    },
    SET_LANGUAGE_NAME_LIST: (state, payload) => {
      return {
        uiStore : {
          ...state.uiStore,
          languageNameList: payload,
        }
      }
    },
    SET_USERNAMEDATA: (state, payload) => {
      return { 
        uiStore : {
          ...state.uiStore,
          userNameData: payload, 
        }
      };
    },
    SET_PARAMFILEDATA: (state, payload) => {
      return { 
        uiStore : {
          ...state.uiStore,
          paramFileData: payload, 
        }
      };
    },
    SET_PARAMFILEDATALIST: (state, payload) => {
      return { 
        uiStore : {
          ...state.uiStore,
          paramFileDataList: payload, 
        }
      };
    },

  };

  initStore(actions, {
    uiStore: {
      notification: null,
      
      gLoading: false,
      mlIsBusy: false,

      srvLoading: false,
      metaLoading: false,

      windowValues: null,
      bowserData: null,
      
      showToggleNav: false,

      isAuth: false,
      isAdmin: false,

      isAllApis: false,

      deferredPrompt: null,
      languageNameList: [],
      userNameData: null,

      paramFileData: null,
      paramFileDataList: null,
      // socketId: '',
      // isBackendApi: false,
      // isSScreen: false,
    }
  });
};

export default configureStore;
